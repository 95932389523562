.menu-buttons-tooltip {
    @include container--colored($color__white, 0);
    border: none;

    .menu-buttons-tooltip__arrow {
        background-color: $color__white;
        border: none;
    }

    .menu-buttons-tooltip__icon {
        color: #072f3d;
    }
}

.menu-buttons-tooltip__text {
    color: #072f3d;
}