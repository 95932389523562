.notification-button__notification-sign {
    left: 50%;
    top: 50%;
    transform: translate(100%, -80%);
    margin: -0.1rem;
    position: absolute;

    &.text-button {
        right: 1%;
        left: unset;
        width: fit-content;
        top: 15%;
        transform: translate(50%, -50%);
    }
}

.notification-button__active {
    color: $color__white;
  }