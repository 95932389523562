$color-title: #2e89ed;

.free-reward-box__container--title {
    font-weight: $font-weight-semibold;
    font-size: $text__size--large !important;
    height: 3rem !important;
    color: $color-title;
    text-transform: uppercase;

}

.free-reward-box__container--content {
    min-height: 8rem !important;
    padding: .5rem;
}

.button-timer__container--button {
    background-color: transparent !important;
    font-weight: $font-weight-bold;
}

.free-reward-box__container--button-action {
    .free-reward-box__button-text {
        p {
            >* {
                &.chips {
                    color: inherit !important;
                    fill: $color__black;
                }
                &.gems {
                    color: inherit !important;
                    fill: $color__gems;
                }
            }
        }
    }
}