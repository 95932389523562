.gf__button--bclass {
    color: $black--7;
    &:hover {
        color: #194098;
    }
}

.gf__input--message {
    border: none !important;
    background-color: #e6e6e6 !important;
}