.rent-button-button__text {
    color: inherit !important;
}

.chips {
    color: inherit !important;
    font-weight: inherit !important;
    fill: inherit !important;
}

.gems {
    color: inherit !important;
    font-weight: inherit !important;
}