.leaderboards-popup__top-tabs__scroll {
    background-color: #00346f;
}

.leaderboards-popup__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.leaderboards-popup__top-tabs__bclass {
    color: #00346f !important;
    background-color: transparent !important;
    width: 100%;
    margin: 0;
    transition: none !important;
    &.top-tabs__selected {
        color: #9fcaf0 !important;
        background-color: transparent !important;

        > p {
            color: #9fcaf0 !important;
        }
    }
}

.leaderboards-popup-top-tabs__list-item--navigation {
    width: 9rem;
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}
