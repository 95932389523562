.challenge__container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-box-shadow-color,
        $container-dark-bottom-border-color);
    min-height: initial;
}

.challenge__main--info__title {
    color: #28afff;
}
