.tut__popup--separator {
    display: none;
}

.tut__popup--closed {
    display: none;
}

.tut__popup {
    border: none !important;
    background: none;
    @include popupContainer(
        $container-bonus-upper-border-color,
        $background-inner-shadow,
        $background-color
    );
}
