.user-input__input {
    border: none;
    background-color: rgba(0,0,0,.3);
    border-bottom: 1px solid #4e8dd4;
    border-radius: 10rem !important;
    height: 5rem;
    padding: 0 3.2rem 0 2rem !important;

    &:active, &:focus {
      border: none;
      background-color: rgba(0,0,0,.3);
      border-bottom: 1px solid #4e8dd4;
      border-radius: 10rem !important;
    }
    &.user-input__input--textarea {
      border-radius: .8rem !important;
      height: inherit !important;
      padding: 1rem 3.2rem 1rem 1rem !important;
      &:active, &:focus {
        border-radius: .8rem !important;
      }
    }
    &.input--has-icon {
      padding: 0 3.2rem 0 5rem !important;
    }
}

.user--input__host {
  height: 4rem;
  @media only screen and (max-width: $break-point--768) {
      height: 4rem;
  }
}