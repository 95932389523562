.game-history-box__container--game-played {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
    overflow: hidden;
    border-radius: $container-border--radius !important;
}

.game-history-box__container--upper-info {
    background-color: transparent !important;
}

.game-history__container--games-wrapper {
    padding: 5%;
    @media only screen and (max-width: $break-point--360) {
        padding: 1%;
    }
}

.game-history-box__text--player-score, .game-history-box__score-container {
    color: $accent--1;
}