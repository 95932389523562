.avatars__matching__text--hint-paragraph {
    width: 30%;
}

.avatars__matching__text--hint-paragraph__container {
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.matching__label--bet {

    font-weight: $font-weight-black !important;
    &:before {
      display: none;
    }
}

.avatars__matching__text--middle__text {
    display: none !important;
}

.avatars__matching__text--middle__svg {
    display: flex !important;
}

.avatars__matching__text-hint-text__seconds {
    font-size: $text__size--large;

    @media only screen and (max-height: $break-point--480) {
        font-size: $text__size--normal;
    }
}

.avatars__matching__text-hint-text__label {
    font-size: $text__size--normal;
    font-weight: $font-weight-light;

    @media only screen and (max-height: $break-point--480) {
        font-size: $text__size--small;
    }
}

.avatars__matching__container--my-avatar {
    border: none !important;
}

.avatars__matching__text--hint-paragraph {
    &.tier0-5 {
        > span {
            color: #362b26 !important;
        }
    }

     &.tier1 {
        > span {
            color: #284c66;
            text-shadow: 0 1px 2px #1f3a4f;
        }
    }

     &.tier2{
        > span {
            text-shadow: 0 1px 2px #0c382e;
            color: #0e3d30;
        }
    }

    &.tier3 {
        > span {
            text-shadow: 0 1px 2px #0d3075;
            color: #d9f3ff;
        }
    }

    &.tier4 {
        > span {
            text-shadow: 0 1px 2px #400a92;
            color: #f7edff;
        }
    }

    &.tier5 {
        > span {
            text-shadow: 0 1px 2px #4c260a;
            color: #602a05;
        }
    }

    &.ranked {
        > span {
            text-shadow: 0 1px 2px #400a92;
            color: #f7edff;
        }
    }
}

.avatars__matching__text--middle__vs--icon {
    color: $color__white;

    &.tier0-5 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #7a6359);
    }

     &.tier1 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #5781a2);
    }

     &.tier2{
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #1ba952);
    }

    &.tier3 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #054fa8);
    }

    &.tier4 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #480fad);
    }

    &.tier5 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #c7731d);
    }

    &.ranked {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem rgb(60, 12, 142));
    }
}

.avatars__matching__container--middle {
    @extend %text;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: #267485;
    &.tier0-5 {
        background: linear-gradient(180deg, #cec2b9, #978172) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

     &.tier1 {
        background: linear-gradient(180deg, #5191b3,#cbdae5) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

     &.tier2 {
        background: linear-gradient(180deg, #1ed18e,#34dfc7) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier3 {
        background: linear-gradient(180deg, #0835d1,#0696d4) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier4 {
        background: linear-gradient(180deg, #5d0bd2,#800ade) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier5 {
        background: linear-gradient(180deg, #e88a17,#e6983b) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.ranked {
        background: linear-gradient(90deg,#410a8d,#5731b3 50%,#410a8d) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }
}

.avatars__matching__bet__icon {
    fill: #232323 !important;
    margin: 0 1rem;
}

.avatars__matching__container--my-avatar {
    border-radius: 1rem;
}