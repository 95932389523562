$tier0-5-color: #2c6875;
$tier1-color: #ffc8a6;
$tier2-color: #232323;
$tier3-color: #432a07;

.mr--scroll__restriction--cover {
    font-size: $text__size--normal;

    &.tier0-5 {
        color: $tier0-5-color;
    }

    &.tier1 {
        color: $tier1-color;
    }

    &.tier2 {
        color: $tier2-color;
    }

    &.tier3 {
        color: $tier3-color;
    }
}

.mr--scroll__wrapper {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: center center !important;
    border-radius: $container-border--radius;

    &.mr--scroll__wrapper__counter-show {
        justify-content: flex-start !important;

        .mr--scroll__room-name {
            margin-top: 4rem;
        }
    }

    &.tier0-5 {
        color: $color--white;
        background: url(imageUrl("lobby/amateurs.png"));
        box-shadow: inset 0 3px 2px #e6d6c5, inset 0 -3px 2px #3d3731, inset 3px 0 2px #6a635c, inset -3px 0 2px #6a635c;

        .mr--scroll__room--info {
            background-color: #8c8075;
            box-shadow: inset 0 3px 2px #c1b5aa, inset 0 -3px 2px #382c21, inset 3px 0 2px #665a4f, inset -3px 0 2px #665a4f;

            .mr--scroll__room--info--icon {
                color: #c4b09d;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #5a534d;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #221a13;
        }
    }

    &.tier1 {
        color: $color--white;
        background: url(imageUrl("lobby/begginers.png"));
        box-shadow: inset 0 3px 2px #c0f9ff, inset 0 -3px 2px #878789, inset 3px 0 2px #42697d, inset -3px 0 2px #42697d;

        .mr--scroll__room--info {
            background-color: #4f6c84;
            box-shadow: inset 0 3px 2px #84a1b9, inset 0 -3px 2px #001830, inset 3px 0 2px #617e96, inset -3px 0 2px #617e96;

            .mr--scroll__room--info--icon {
                color: #80b3dd;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #4f6c84;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #032038;
        }
    }

    &.tier2 {
        color: $color--white;
        background: url(imageUrl("lobby/beginners.png"));
        box-shadow: inset 0 3px 2px #5bffff, inset 0 -3px 2px #00852f, inset 3px 0 2px #009856, inset -3px 0 2px #009856;

        .mr--scroll__room--info {
            background-color: #0f7352;
            box-shadow: inset 0 3px 2px #44a887, inset 0 -3px 2px #001f00, inset 3px 0 2px #248867, inset -3px 0 2px #248867;

            color: $color--white;

            .mr--scroll__room--info--icon {
                color: #1bbc87;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #0f7352;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #001f00;
        }
    }

    &.tier3 {
        color: $color--white;
        background: url(imageUrl("lobby/advanced.png"));
        box-shadow: inset 0 3px 2px #5be2ff, inset 0 -3px 2px #000076, inset 3px 0 2px #001d8a, inset -3px 0 2px #001d8a;

        .mr--scroll__room--info {
            background-color: #07306d;
            box-shadow: inset 0 3px 2px #3c65a2, inset 0 -3px 2px #000019, inset 3px 0 2px #234c89, inset -3px 0 2px #234c89;

            .mr--scroll__room--info--icon {
                color: #1472ff;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #07306d;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #ffffff;
        }
    }

    &.tier4 {
        color: $color--white;
        background: url(imageUrl("lobby/professionals.png"));
        box-shadow: inset 0 3px 2px #d05bff, inset 0 -3px 2px #0a0087, inset 3px 0 2px #2a0ba8, inset -3px 0 2px #2a0ba8;

        .mr--scroll__room--info {
            background-color: #35076e;
            box-shadow: inset 0 3px 2px #6a3ca3, inset 0 -3px 2px #00001a, inset 3px 0 2px #41137a, inset -3px 0 2px #41137a;

            .mr--scroll__room--info--icon {
                color: #9e4fff;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #35076e;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #ffffff;
        }
    }

    &.tier5 {
        color: $color--white;
        background: url(imageUrl("lobby/legends.png"));
        box-shadow: inset 0 3px 2px #fff498, inset 0 -3px 2px #803200, inset 3px 0 2px #9e450c, inset -3px 0 2px #9e450c;

        .mr--scroll__room--info {
            background-color: #633b07;
            box-shadow: inset 0 3px 2px #98703c, inset 0 -3px 2px #0f0000, inset 3px 0 2px #714713, inset -3px 0 2px #714713;

            .mr--scroll__room--info--icon {
                color: #e38b19;
            }
        }

        .mr--scroll__room-name-p {
            >span {
                color: #633b07;
                font-size: 2rem;
                font-weight: $font-weight-extra-bold;
                text-shadow: none;
            }
        }

        .mr--scroll__transactions {
            color: #ffffff;
        }
    }
}

.mr--scroll__button-bclass {
    font-size: $text__size--large;
    height: 4.5rem;
    box-shadow: 0 2px 3px rgb(0 0 0 / 90%);

    &.tier0-5 {
        >p {
            color: #542b00 !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }

    &.tier1 {
        >p {
            color: #542b00 !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }

    &.tier2 {
        >p {
            color: #001f00 !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }

    &.tier3 {
        >p {
            color: #001c59 !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }

    &.tier4 {
        >p {
            color: #35076e !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }

    &.tier5 {
        >p {
            color: #250100 !important;
            text-shadow: 0 4px 3px #ff7a00 !important;
        }
    }
}

.mr--scroll__room-name {
    font-size: 3rem !important;
    color: $color__white;
    width: 100%;
    height: 20%;
    position: static !important;
    width: 100% !important;
    transform: translate(0, 0) !important;
}

.mr--scroll__room-name-p {
    text-shadow: 0 2px 1px #2d3059;
    color: #dddff2;
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

.mr--scroll__room--info {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    border-radius: 10rem;
    width: 27rem;
    height: 4.5rem;
    padding: 1rem 0;
    margin: auto;

    .mr--scroll__room--info--icon {
        color: #7c86ff;
    }
}

.mr--scroll__room--info--p {
    display: flex;
    align-items: center;
    margin: 0 .5rem;
    word-break: break-word;
}

.mr--scroll__room--info--icon {
    width: 2rem !important;
}

.mr--scroll__room--info--span {
    margin-left: 0.5rem;
    font-weight: $font-weight-bold;
    vertical-align: middle;
}

.mr--scroll-reward__label {
    font-weight: $font-weight-black !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mr--scroll-reward__value-label {
    font-weight: $font-weight-black !important;
}

.mr--scroll-bet__value-label {
    font-weight: $font-weight-black !important;
    font-size: $text__size--normal;
    margin-right: .5rem;
}

.mr--scroll-reward__value,
.mr--scroll-bet__value {
    font-weight: $font-weight-black !important;
}

.mr--scroll__restriction--cover__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black__transparent--05;
    display: none;
    border-radius: 1.2rem;

    &.mr--scroll__restriction--cover__container__not-permitted {
        display: block;
        background-color: $black__transparent--05;
    }
}

.mr--scroll__restriction--cover {
    visibility: hidden !important;
}

.mr--scroll__transactions {
    height: 25%;
    width: 90%;
    margin-bottom: 1rem;

    .mr--scroll__transactions--image {
        background-image: url(imageUrl("lobby/room_default.png"));
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 100%;
    }
}

.mr--scroll__button {
    margin-left: calc((100% - 27rem)/2) !important;
    width: 27rem !important;
    margin-top: 1rem !important;

    @media screen and (max-height: $break-point--1280) {
        margin-bottom: 2rem !important;
    }

    @media screen and (max-height: $break-point--768) {
        margin-bottom: 2.5rem !important;
    }
}

.mr--scroll__room-separator {
    display: flex !important;
    width: 75%;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mr--scroll__room-separator__svg {
        width: 100%;
        display: flex !important;
    }

    &.tier0-5 {
        color: #8c8075;
    }

    &.tier1 {
        color: #759ab1;
    }

    &.tier2 {
        color: #18b08b;
    }

    &.tier3 {
        color: #1460a5;
    }

    &.tier4 {
        color: #6014ae;
    }

    &.tier5 {
        color: #a57831;
    }
}

.mr--scroll__container--wrapper-play {
    margin: 0 !important;
}

.mr--scroll__players-counter {
    padding: .5rem !important;
    background-color: #00000098 !important;
    color: $color--white;
    border-radius: 10rem;
    width: 7rem;
    left: 50%;
    transform: translateX(-50%);
}