.shop-inventory__container--tile {
    border: none !important;
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
    border-radius: $container-border--radius !important;
}

.shop-inventory__container--global-items, .shop-inventory__container--game-spec-items {
    margin: 2rem 0;
}

.shop-inventory__container--item-text {
    margin-top: 1rem;
}