.bottom-bar__container {
    height: 8rem !important;
    align-items: flex-start !important;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: $break-point--768) {
        height: 6.5rem !important;
    }
}

.bottom-bar__container--button {
    top: 0;
    position: relative;
    height: 100% !important;
}

.bottom-bar__wrapper {
    background: linear-gradient(#4607ab, #140734);
    width: 97% !important;
    margin: auto;
    border-radius: 10rem;
    box-shadow: 0 4px 6px #05020d, inset 0 2px 1px #e3a7ff, inset 1px 0 1px #6a27de, inset -1px 0 1px #6a27de;
}

.bottom-bar__link-overlay {
    background-color: $popup--backdrop;
}

.bottom-bar__button-icon {
    &.bottom-bar__home_button {
        .bc__button--icon-cmp {
            color: #f6ca45;
            font-size: $text__size--x-large !important;
        }
        > p {
            color: #f6ca45;
        }
    }
    .bc__button--icon-cmp {
        font-size: $text__size--x-large !important;
    }
    > p {
        margin-top: .5rem;
        @media only screen and (max-width: $break-point--568) {
            font-size: $text__size--smaller !important;
        }
    }
}

.bottom-bar__light {
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 5rem;
    margin-top: 3rem;
    display: block !important;
    height: 7rem;
    background-color: #8f4731;
    filter: blur(16px);
}