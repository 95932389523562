$public-chat-bg-color: #efefef;

.gf__container--chat {
    background-color: $public-chat-bg-color;
}

.gf__container--conversations {
    background-image: none;
    background-color: $public-chat-bg-color;
    background-repeat: repeat;
    background-position: center;
    background-size: 15rem;
}

.gf__wrapper--convs {
    @include scrollbar(0.2rem, 0.2rem, #002431, transparent, 0, 10%, true, #a3a3a3);
    //
    //height: calc(100% - 7rem) !important;
    //width: calc(100% - .5rem) !important;
    //
    //@media only screen and (max-width: $break-point--768) {
    //    height: calc(100% - 6rem) !important;
    //}
}

.gf__container--messages {
    @include scrollbar();
}

.gf__container--footer {
    height: 7rem !important;

    @media screen and (max-width: $break-point--768) {
        height: 6rem !important;
    }
}
