$rankings-tile-border-top-color: #ffb7b5;
$rankings-tile-border-bottom-color: #651946;

$color-top-label-title-text-shadow: #7c3802;
$color-top-label-first: #e67b84;
$color-top-label-second: #c83554;

.rankings-tile__wrapper {
    transition: transform 0.6s ease-in-out, border-bottom-color 0.6s !important;
    transform-style: preserve-3d;
}


.rankings-tile__initial,
.rankings-tile__leaders {
    background: linear-gradient(#4555ab, #8822ed);
    box-shadow: inset 0 3px 2px #859af0, inset 0 -3px 2px #2b008e, inset 3px 0 2px #0c005f, inset -3px 0 2px #0c005f;
}


.rankings-tile__image--icon {
    left: 50%;
    transform: translateX(-50%);
    top: 0.25rem;
    max-height: calc(100% - 1rem);
}

.rankings-tile__wrapper--texts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: $font-weight-extra-bold !important;
    font-size: 2.6rem;
    color: #fcfcfc;
    text-shadow: 1px 1px 2px #21254b;
    text-transform: uppercase;

    > span {
        text-transform: lowercase;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--large;
    }
}

.rankings-tile__leaders {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rankings-tile__leaders--top {
    background: #ff5e5e;
    padding: .25rem 1.5rem;
    position: absolute;
    box-sizing: content-box;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    max-height: 3rem;
    border-radius: $container-border--radius;
}

.rankings-tile__leaders--top-img {
    width: 100%;
    height: 100%;
}

.rankings-tile__leaderboards--name {
    font-size: $text__size--normal;
    text-align: center;
    color: $color__white;
    font-weight: $font-weight-bold;
    text-shadow: 0 2px 5px $color-top-label-title-text-shadow;
    white-space: nowrap;

    @media screen and (max-width: $break-point--360) {
        font-size: $text__size--small;
    }
}

.rankings-tile-bclass {
    height: 9rem !important;
    width: 6rem !important;

    @media screen and (max-width: $break-point--480) {
        height: 8rem !important;
        width: 5rem !important;
    }
}

.rankings-tile__professionals {
    font-size: 0.8rem;
}