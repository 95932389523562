.club--info--xp--bar__points {
    > .label {
        color: #a6aeff;
    }
    > .amount {
        color: #f5a724;
    }
}

.club--info--xp--bar__container--ranking {
    > .label {
        color: #a6aeff;
    }
}