.scroll-button-control__button {
    background: linear-gradient(#ffd43c, #ffbb01) !important;
    box-shadow: 0 6px 10px #000000c4;
}

.scroll-button-control__container {
    background-color: #0b0e23;
}

.scroll-button-control__fragmet {
    color: #6572eb;
    font-style: italic;
}

.scroll-button-control__fragmet__current {
    color: #ac3900;
    font-weight: $font-weight-bold;
    text-shadow: 0 1px 0 #812e05;
}

.scroll-button-control__container {
    box-shadow: inset 0px 1px 0px #020c10, inset 0 -1px 0 #293071;
}