%blitz-round {
    min-width: unset !important;
}

.blitz-tournament__container--box-left-line {
  border-left: .2rem solid #474d8b !important;
  > .line-box {
    border-bottom: .2rem solid #474d8b !important;
  }
}

.team-player {
  @include container--colored($color__black);
}


.blitz-tournament__container--box-right-line {
  &.final {
    border-right: .2rem solid #474d8b !important;
  }
  > .line-box {
    border-bottom: .2rem solid #474d8b !important;
  }
}

.blitz-tournament__container--round-bottom-line {
  @media only screen and (max-width: $break-point--980) {
    > .line-box {
      border-right: .2rem solid #474d8b !important;
    }
  }
}


.blitz-tournament__container--box-left-line {
  position: relative;
  left: -0.5%;
  height: 50%;
  width: 10%;
  border-left: .2rem solid #474d8b;
  @media only screen and (max-width: $break-point--980) {
    display: none !important;
  }
  > .line-box {
    width: 105%;
    top: 0;
    border-bottom: .2rem solid #474d8b;
  }
  &.winners {
    border-left: none !important;
  }
  &.first-col {
    border-left: none !important;
    > .line-box {
      opacity: 0;
    }
  }
}

.blitz-tournament__wrapper {
  @include scrollbar(0.2rem, 0.2rem, #8a48c4);
}