.game-box__container--box {
    border: none;
    border-radius: $container-border--radius !important;
    border-bottom: 0.2rem solid #153a48;
    background: linear-gradient(180deg, #f7f6f5, #d7d6d3);
    padding: 0 !important;
    overflow: hidden;
    &.final {
        background: linear-gradient(180deg, #b6e026, #abdc28);
        border: none;
        border-radius: $radius--default;

        .box-footer {
            background-color: transparent !important;
            > .box-footer-text {
                background-color: #c3f733;
            }
        }
    }
    > .box-title {
        background-color: rgba(0, 0, 0, 0.1);
        width: 100% !important;
        margin: 0 !important;
        padding: .5rem 0;
        > p {
            font-weight: $font-weight-bold;
            color: #0c0e26;
            font-size: 1.9;
        }
    }
    > .box-content {
        > .versus-icon {
            > p {
                color: #0c0e26 !important;
            }
        }
    }
    .box-footer {
        background-color: transparent !important;
        margin: 0.5rem 0.5rem 0.5rem 0.5rem !important;
        > .box-footer-text {
            background-color: #f7f6f5;
            color: #0c0e26;
            font-weight: $font-weight-bold;
            border-radius: $container-border--radius;
        }
    }
}

.results-container {
    order: 3;
    display: flex;
    justify-content: space-around;
    margin: 0 1.5rem;
    color: #0c0e26 !important;
}

.game__avatar__container {
    >.player_result {
        margin-top: 0;
        color: #0c0e26 !important;
    }
}

.game__box-team-container{
    >.player_result {
        margin-top: 0;
        color: #0c0e26 !important;
    }
}
