$background-color: #2261a8;
$background-inner-shadow: #2261a8;
$container-bonus-upper-border-color: #2e83c4;

.popup-container__wrapper--content {
    border: none !important;
    background: none;
    @include popupContainer($container-bonus-upper-border-color,
    $background-color, $background-inner-shadow);
}

.popup__outlet--wrapper-title {
    background-color: transparent !important;

    &:before {
        display: none;
    }

    .popup-title-bar__container--close {
        display: none !important;
    }
}