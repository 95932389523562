$gray: #a6c5ca;
$dark-gray: #9fa3a5;
$light-gray: #ebeeef;
$bg-gray: #edf0f1;

.settings-option__wrapper {
    flex-wrap: nowrap !important;
    .settings-option__top-section {
        > .option-name {
            .option-container {
                display: flex;
                flex-direction: column;
                > p {
                    margin-left: 0 !important;
                    @extend %text;
                    color: $popup-accent-1;
                }

                > .settings-option__hint-inside {
                    display: block !important;
                    > p {
                        max-width: 95%;
                        @extend %text;
                        color: $popup-font--color;
                    }
                }
            }

            .settings-option__icon {
                color: $popup-accent-1;
                margin-right: 1rem;
                width: 3rem;
                flex-shrink: 0;
            }
        }
        > .toggle-button {
            &.lang,
            &.country {
                width: 20rem !important;
            }
        }
    }
    > .settings-option__hint-outside {
        display: none !important;
        > p {
            @extend %text;
            color: $popup-font--color;
        }
    }
}

.settings-opion__icon-notification {
    display: block !important;
}

.custom-select_option {
    color: $color__black;
}

.custom-select__wrapper {
    position: unset !important;
}

.custom-select__container {
    position: unset !important;
    &.custom--select__options--visible {
        .custom-select__container--options {
            background-color: $color__white;
            border: none;
            box-shadow: 0 0 3rem .5rem $black__transparent--07;
            border-radius: .8rem .8rem .8rem .8rem;
            left: 0;
            top: 6rem;
            &.button {
                border: .1rem solid $accent--1;
                border-radius: .8rem;
            }
        }
        .custom-select__input {
            border: none;
            .custom-select__dropdown-arrow {
                color: $popup-accent-1;
            }
        }
    }

    .custom-select__input {
        position: unset !important;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        > p {
            font-size: $text__size--normal;
            color: $popup-font--color;
        }
        .custom-select__dropdown-arrow {
            color: $popup-font--color;
        }
        color: $popup-font--color;
    }
}

.custom-select__container--options {
    @include container--colored($container-dark);
    @include scrollbar(0.4rem, 0.2rem, $dark-gray, transparent, 0, 10%, true, $light-gray);
    background-color: transparent;
    border: none;
    width: 100% !important;
    top: 6rem;
    @media only screen and (max-width: $break-point--768) {
        top: 6rem !important;
    }
}
.settings-option__custom-select__option {
    border-radius: $radius--default;

    > p {
        font-size: $text__size--normal;
        @extend %text;
        color: $popup-font--color;
    }

    &:hover {
        background-color: #edf0f1;
        > p {
            color: #194098;
        }
    }
}

.settings-option__custom-select__icon {
    color: $popup-accent-1;
}