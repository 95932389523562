$background-color-phrase-item: #0f5b78;

.custom-phrases__container--item-special {
    border: none !important;
    border-top: 1px solid #b234ff !important;
    background-color: #591ab7 !important;
    padding: 1rem !important;
    > p {
        color: $color--white !important;
    }
}

.custom-phrases__container--item-default {
    background-color: #1339b3 !important;
    border-top: 1px solid #5660bc !important;
    border-radius: .8rem !important;
    &.vip {
        border: none !important;
        background-color: #155cff !important;
        border-top: 1px solid #bde2ff !important;
    }

    &:after {
        border-radius: .7rem !important;
    }
}

.custom-phrases__container--title {
    position: relative;
    background-image: none !important;
    > p {
        color: #ffffff61;
        padding: 0 !important;
    }
}

.phrases__vip-crown__container {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    background: #18578f;
    border-radius: 50%;
    border-top: 1px solid #55adee;
    border-bottom: 1px solid #03040e;
    display: flex !important;
    .phrases__vip-crown {
        font-size: 1.8rem;
        margin: 0.2rem 0.4rem;
    }
}
.custom-phrases__constainer-list {
    .vip {
        position: relative;
        background-color: lighten( $secondary, 5% );
        border: 1px solid $accent--1;
        color: $color__white !important;
        font-weight: 400;
        &.player-non-vip {
            border: none !important;
            &:before {
                    display: none !important;
                }
            }
        }
    }
        
.custom-phrases__list {
    background-color: transparent !important;
}

.player-non-vip {
    &:after {
        background-color: rgb(2 18 53 / 73%);
    }
}