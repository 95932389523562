/****************************
*   Promo codes skin        *
****************************/

$promo-code-background: imageUrl('shop/promo_codes/main.svg');
$promo-code-coins: imageUrl('shop/promo_codes/promo-card-coins.png');

// Styles
.promo-code__wrapper {
  background: url($promo-code-background) no-repeat;
}


.promo-code__container--input {
  .promo-code__input {
    background: $color--white;
    border: none !important;
    color: $color--black !important;
  }
}

.promo-code__container--main {
  padding: 0 8rem !important;
  @media only screen and ( max-width: $break-point--480) {
    padding: 0 6rem !important;
  }
  @media only screen and ( max-width: $break-point--380) {
    padding: 0 4rem !important;
  }
  @media only screen and ( max-width: $break-point--360) {
    padding: 0 2rem !important;
  }
}
