.popup-title-bar__container--close-redesign {
    display: initial;
}

.popup-title-bar__container--close {
    display: none !important;
}

.popup-title-bar__container--game-title {
    background-color: transparent !important;
    color: #dbddf0;
    &:before,
    .popup-title-bar__image--game-title {
        display: none;
    }

    .popup-title-bar__text--static-title {
        font-weight: $font-weight-bold;
    }
}