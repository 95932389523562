$container-bonus-background-color: #001d5e;
$color-check-icon: #abdc28;
$color-current-reward: #a9dff4;


.daily-reward--checked-image--wrapper {
    background: $container-bonus-background-color;
    border-radius: 50%;
}

.daily-reward--title {
    font-weight: $font-weight-semibold;
    color: #001d5e;
}

.daily-reward {
    border-radius: $border--radius;
    background: linear-gradient(0deg, #5c89ec 0%, #a0c6fb 100%);
    transform: translateZ(0);

    &.current {
        box-shadow: 0 0 6px 3px $color-current-reward;
        border: none !important;
    }

    &.registerAdvent {
        @include coloredContainer($container-dark-background-color,
            $container-dark-upper-border-color,
            $container-dark-box-shadow-color,
            $container-dark-bottom-border-color);

        background: $container-dark-background-color;

        .daily-reward--title {
            color: white;
        }

        &.current {
            background-color: #591ab7!important;
            border-top: 2px solid #b234ff!important;
            box-shadow: 0 0 12px 4px $color--selected,0 0 0px 2px $color--selected;
        }

        &.checked {
            opacity: 0.4;
            filter: brightness(0.5);
        }

        &.highlighted {
            background-color: #591ab7!important;
            border-top: 2px solid #b234ff!important;
        }
    }
}

.daily-reward--image-wrapper {
    @media only screen and (max-width: $break-point--320) {
        width: 100% !important;
    }
}

.daily-reward-container__reward--value {
    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--small !important;
    }
}

.checked-image {
    &.not-round {
        display: none !important;
    }
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 70% !important;
    height: 70% !important;
    display: initial !important;
    color: $color-check-icon;
    z-index: 2;
}

.daily-reward-container__reward {
    background: linear-gradient(0deg,#001d5e,#3f42bc)!important;
    border-radius: 2rem;
    padding: 0.3rem 0.5rem;
    color: $color-day-reward-text;
}

.daily-reward--checked-image--wrapper {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
    transform: translate(-5%, -5%) !important;
}
