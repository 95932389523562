.tournaments__top-tabs__scroll {
    background-color: $color__white;
}

.tournaments__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.tournaments__top-tab__button {
    min-width: 15rem !important;
}

.tournaments__top-tab__button-bclass {
    color: $color--white !important;
    background-color: transparent !important;
    width: 100%;
    margin: 0;
    transition: none !important;
    &.tournaments__top-tabs__selected {
        color: $color-tab-selected !important;
        background-color: transparent !important;

        > p {
            color: $color-tab-selected !important;
        }
    }
}

.tournaments-top-tabs__list-item--navigation {
    width: 9rem;
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}
