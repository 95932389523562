/***************************************
*      Blitz-tournament skin           *
***************************************/

$first-place-img: imageUrl('blitz-tournament/1.png');
$second-place-img: imageUrl('blitz-tournament/2.png');
$bling-bling: imageUrl('lobby/bling_bling.png');

$won-popup-shadow-color: #53e4f6;
$won-popup-shadow-inner-color: #00000036;
$won-popup-container-color: #00000021;

.won-popup__prize--amount {
    >span {
        @include chip-sign(2.4rem);
        @extend %text;
        color: $color--chips;
        font-weight: $font-weight-bold;
    }

    text-shadow: 0 -2px 0 #fed702,
    0 1px 0 #9f4301,
    0 2px 2px #531049;
}

.won-image {
    display: none;
}

.won-popup-image__new-design {
    display: block;
    width: 14rem;
    height: 14rem;
    margin: 0 auto;
    background-color: $cup-container-color;
    box-shadow: inset 0px 1px 0px 0px $cup-shadow-inner-color,
        inset 0px -1px 0 $cup-shadow-color, 0 0 3px 3px #2a8a9c;
    border-radius: 50%;
    position: relative;
}

.won-popup-image__new-design--img {
    @include background-image($second-place-img, 100%, 100%, left, center);
    background-size: auto 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    &.first {
        @include background-image($first-place-img, 100%, 100%, left, center);
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        @include background-image($bling-bling, 100%, 100%, left, center);
    }
}