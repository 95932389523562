.notification-wrapper__container--main {
    &.gifts,
    &.invites,
    &.likes,
    &.likesandratings,
    &.club,
    &.system {
        @include coloredContainer($container-dark-background-color,
            $container-dark-upper-border-color,
            $container-dark-box-shadow-color,
            $container-dark-bottom-border-color);
    }

    &.highlighted {
        @include coloredContainer(#591ab7,
            #b234ff,
            $container-dark-box-shadow-color,
            rgb(0 0 0 / 30%));
    }
}