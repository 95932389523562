.level-up-rewards--container {
    background-color: #02356b;
    border-bottom: 1px solid #00a2ef;
    box-shadow: inset 0 0 3px 2px rgba(0, 0, 0, .5), inset 0px 0px 7px 4px #194b81;

    &.ready-for-claim {
        background-color: #1172d5cf;
        border-bottom: 1px solid #acf7fa;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 5px 4px #318dd2;
    }
}

.level-up-rewards-container--wrapper {
    background-color: #0061bf;
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #12d4fa;
    border: none;

    &.ready-for-claim {
        background-color: #38c4ff;
        box-shadow: 0 2px 13px 0 #1f921e, 0 8px 13px 2px rgba(0, 0, 0, .5), 0 0 5px 4px #2db41b, inset 0 1px 1px 0 #edffff;
        border: none;
    }
}

.level-up-rewards-icon--wrapper {
    background-color: #2261a8;
}

.level-up-rewards-icon--wrapper-secondary {
    &.lock {
        background-image: linear-gradient(to bottom, #2267ac, #1b5b9d, #144f8e, #0b447f, #023971);
    }
}

.level-up-rewards-container--overlay {
    background-color: #043772cf;
}

@keyframes level-up-rewards-container-animation {
    0% {
        background-color: #02356b;
    }

    25% {
        background-color: #054384;
    }

    50% {
        background-color: #09529e;
    }

    75% {
        background-color: #0d62b9;
    }

    100% {
        background-color: #1172d5cf;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), inset 0 0 5px 4px #318dd2;
        border-bottom: 1px solid #acf7fa;
    }
}

@keyframes level-up-rewards-container-wrapper-animation {
    0% {
        background-color: #0061bf;
        transform: scale(1);
    }

    25% {
        background-color: #007ad2;
    }

    50% {
        background-color: #0092e3;
        transform: scale(1.03);
    }

    75% {
        background-color: #00abf2;
    }

    100% {
        background-color: #33c3ff;
        transform: scale(1);
        box-shadow: 0 2px 9px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 1px 0 #edffff;
    }
}

@keyframes level-up-rewards-container-wrapper-fade-out {
    0% {
        background-color: #33c3ff;
        box-shadow: 0 2px 4px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 3px 2px #2db41b, inset 0 1px 0px 0px #edffff;
    }

    25% {
        background-color: #00abf2;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #12d4fa;
    }

    50% {
        background-color: #0092e3;
    }

    75% {
        background-color: #007ad2;
    }

    100% {
        background-color: #0061bf;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 1px 0 #12d4fa;
    }
}

@keyframes level-up-rewards-container-fade-out {
    0% {
        background-color: #1172d5cf;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), 0px 1px 0px 0px #318dd2;
    }

    25% {
        background-color: #0d62b9;
        box-shadow: none;
        border-bottom: 1px solid #00a2ef;
    }

    50% {
        background-color: #09529e;
    }

    75% {
        background-color: #054384;
    }

    100% {
        background-color: #02356b;
        box-shadow: none;
        border-bottom: 1px solid #00a2ef;
    }
}