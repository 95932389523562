$name-font-size: 4rem;
$name-font-size-mobile: 7vw;
$color-dark: #072f3d;
$bling-bling: imageUrl('lobby/bling_bling.png');

.btt__wrapper {
    border-top: 0.2rem solid #90ffff;
    background: linear-gradient(#48d1fa, #0566d1);
    border-radius: $container-border--radius;
}

.btt__container--top-bar {
    &:not(.middle) {
        display: none !important;
    }

    &.middle {
        padding: 0 !important;
        display: flex !important;
        background-color: transparent !important;
    }
}

.btt__info-wrapper {
    flex-direction: row !important;
    font-size: $text__size--large  !important;
}

.btt__text--place-value-icon {
    font-size: inherit !important;
}

.btt__text--place-text {
    font-size: inherit !important;
}

.btt__text--place {
    font-size: inherit !important;
    text-transform: uppercase;
}

.btt__container__name {
    display: none;
}

.btt__container__name--new-design {
    display: block;
}

.btt__play--button-wrapper {
    display: block;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0;
    .button {
        white-space: pre-wrap;
    }
}

.btt__button--disabled {
    >img {
        width: 3rem;
    }
}

.btt__play--button {
    margin: 1rem auto 2rem auto !important;
    width: 40rem;
    max-width: 95%;
    height: 5rem !important;

    @media screen and (max-width: $break-point--768) {
        height: 4rem !important;
    }

    @media screen and (max-width: $break-point--568) {
        width: 100%;
    }
}

.btt__play--button-bclass {
    >p {
        font-size: $text__size--large  !important;
    }
}

.btt__container--room-icon {
    display: none;
}

.tile__text--name--new-design {
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    font-size: $name-font-size  !important;
    text-shadow: 0 2px 0 #00d8ff, 0px 7px 14px #004eed;
    text-align: center;
    line-height: 1;

    >span {
        display: block;
        font-size: $name-font-size * 2 !important;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: $name-font-size-mobile  !important;

        >span {
            font-size: $name-font-size-mobile * 2 !important;
        }
    }
}

.btt__container__description {
    display: none;
}

.btt__container__description--new-design {
    margin-top: 1rem;
    display: flex;
}

.tile__text--description--new-design {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.tile__text--description--new-design-entry {
    color: $color-dark;
    font-size: $text__size--large;
}

.btt__container--bet {
    display: none;
}

.btt__container--img--new-design {
    display: flex;
    width: 100%;
    height: 40%;
}

.btt__container--img--info {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.btt__container--img--icon {
    flex: 1;
    position: relative;

    >.btt__container--img--icon--cup__container {
        height: 300%;
        left: 50%;
        position: absolute;
        top: 50%;
        width: 100%;
        >.btt__container--img--icon-cup {
            position: absolute;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url(imageUrl("blitz-tournament/cup.png"));
        }
    }

    >.btt__container--img--icon-bling {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(imageUrl("lobby/bling_bling.png"));
        height: 200%;
        top: 40%;
        width: 80%;
        position: absolute;
        top: -60%;
        left: 10%;
    }
}

.btt__container--img--info-place {
    font-size: $text__size--x-large;
    font-weight: $font-weight-bold;
    font-style: italic;
    line-height: 1;
    margin-top: 1rem;
    display: flex;
    flex-flow: column;

    @media screen and (max-width: $break-point--568) {
        margin-top: 0.5rem;
        font-size: $text__size--large;
    }
}

.btt__text--place-text--new-design {
    color: $color-dark;
}

.btt__container--img--info-place--chip {
    font-size: $text__size--x-large + 0.5rem !important;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--large + 0.5rem !important;
    }
}

.blitz-restriction-cover {
    &.royal16, &.royal8 {
        .restriction-level--host {
            @extend %royal16-tile-info-container;
            font-weight: $font-weight-bold;
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
        }
    }
}

.btt__wrapper.btt__medium-tile {
    .btt__container--middle {
        justify-content: flex-end !important;
    }

    .tile__text--name--new-design {
        font-size: $name-font-size * 0.8 !important;

        >span {
            font-size: $name-font-size * 1.2 !important;
        }

        @media screen and (max-width: $break-point--568) {
            font-size: $name-font-size-mobile  !important;

            >span {
                font-size: $name-font-size-mobile * 1.2 !important;
            }
        }
    }

    .btt__container--img--icon {
        display: none;
    }

    .tile__text--description--new-design {
        font-size: $text__size--normal;
    }

    .tile__text--description--new-design-entry {
        font-size: $text__size--normal;
    }

    .btt__container--img--new-design {
        height: auto;
    }

    .btt__container--img--info-place {
        font-size: $text__size--large;

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--normal;
        }
    }

    .btt__container--img--info {
        flex-flow: row;
        justify-content: space-around;
    }

    .btt__container--img--info-place--chip {
        font-size: $text__size--large  !important;

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--normal  !important;
        }
    }

    .btt__play--button {
        height: 4rem !important;
    }
}