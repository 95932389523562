$top-bar-color: transparent;

.top-bar__wrapper {
  background-color: $top-bar-color !important;

  .top-bar__container--main {
    background-color: $top-bar-color !important;
  }
}

.top-bar__currency-wrapper {
  background-color: $top-bar-color !important;
  font-size: 4rem !important;
  flex-wrap: nowrap !important;
}

.top-bar__container--challenges{
  @media screen and (max-width: $break-point--360) {
    width: 5rem !important;
  }
}

.top-bar__settings__menu-icon {
  @media only screen and (max-width: $break-point--768) {
    font-size: 2.4rem !important;
  }
}

.top-bar__dot {
    transform: translate(40%, -110%) !important;
}

.top-bar__dot--icon {
    border-color: #303460 !important;
}

.top-bar__challenges-icon-active {
    color: $color__white;
}
