.completed-challenges-list-preview__container {
    @include coloredContainer($container-dark-background-color,
      $container-dark-upper-border-color,
      $container-dark-inner-shadow-color,
      $container-dark-bottom-border-color);
}

.completed-challenges-list-preview_title { 
  font-weight: $font-weight-semibold;
  color: #dbddf0;
}