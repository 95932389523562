.menu--dropdown--item--wrapper-host {
    &:hover {
        background-color: #dfe0f8 !important;
        border-radius: $radius--default;
        p {
            color: #2e3aff !important;
            text-decoration: none !important;
        }
        .bc__button--icon-cmp {
            fill: #2e3aff !important;
        }
    }
}