.restriction-vip--host {
    font-weight: $font-weight-extra-bold;
    text-transform: uppercase;
    background: linear-gradient(#53565c, #000000);
    padding: 0.2rem 1.5rem;
    border-top: 2px solid #61656b;
    box-shadow: 0 2px 2px 0 $black__transparent--05;
}

.restriction__vip {
    line-height: 1.8rem;
    font-size: 1.8rem;
}

.restriction__vip--text {
    display: none;
}

.restriction__vip--icon {
    display: none !important;
    margin: 0 0.1em 0 0;
}

.restriction__vip--icon--new-design {
    font-size: $text__size--x-large;
    display: inline-block !important;

    @media screen and (max-width: $break-point--768) {
        font-size: $text__size--large;
    }
}