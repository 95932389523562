.club-player-profile-info__container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.club-player-profile-info__title { 
    font-weight: $font-weight-semibold;
    color: #dbddf0;
}