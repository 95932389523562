.cashback--tile__content--container {
    --cashback-image: #{url(imageUrl("lobby/cashBack.png"))};
    background: 50% 90% / 80% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
    box-shadow: inset 0 3px 2px #c2ceff80, inset 0 -3px 2px #2b008e, inset 3px 0 2px #0e0071e3, inset -3px 0 2px #0e0071e3;
    border-radius: $border--radius;
    justify-content: space-around;
    @media screen and (max-width: $break-point--680) {
        background: 50% 90% / 100% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
    }

    &.cashback__extra-small-tile {
        background: 100% 90% / 40% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
        @media screen and (max-width: $break-point--768) {
            background: 100% 90% / 60% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
        }

        @media screen and (max-width: $break-point--440) {
            background: 130% 90%/80% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
        }

        @media screen and (max-width: $break-point--360) {
            background: 200% 90%/90% no-repeat var(--cashback-image), linear-gradient(#4555ab, #8822ed);
        }
    }
}


.cashback--tile__text {
    display: initial !important;
    max-width: 70%;
}

.cashback--tile__text--container {
    position: relative !important;
    top: 0;
    left: 0;
    width: fit-content;
    height: 100%;
    max-width: 50%;
    justify-content: flex-start !important;
    align-content: space-between;
    text-transform: uppercase;
    padding: 0.5rem 1rem 0rem !important;
    height: 100%;
    background-color: transparent !important;
    display: flex !important;
    flex-flow: column nowrap !important;
    &.with__progress-bar {
        height: calc(100% - 3.5rem) !important;
        @media screen and (max-width: $break-point--768) {
            height: calc(100% - 1.5rem);
        }
    }
    @media screen and (max-width: $break-point--680) {
        max-width: 100%;
    }

    &.cashback_text_extra-small-tile {
        justify-content: center !important;
        .cashback--tile__text,
        .cashback--tile__text--name {
            height: initial !important;
            font-size: 3rem !important;
            color: #e6e6e6;
            text-shadow: 1px 3px 4px #171175;
            line-height: 3rem !important;
            text-align: initial !important;
            font-weight: $font-weight-extra-bold !important;
            @media screen and (max-width: $break-point--1440) {
                font-size: 3rem !important;
            }
            @media screen and (max-width: $break-point--440) {
                font-size: 2.4rem !important;
                line-height: 2.4rem !important;
            }
        }
    }
    .cashback--tile__text,
    .cashback--tile__text--name {
        font-size: 4rem !important;
        color: #e6e6e6;
        text-shadow: 1px 3px 4px #171175;
        line-height: 4rem !important;
        text-align: initial !important;
        font-weight: $font-weight-extra-bold !important;
        @media screen and (max-width: $break-point--1440) {
            font-size: 4rem !important;
        }
    }

    .cashback--tile__timer {
        display: initial !important;
        text-align: initial !important;
        font-size: 1.3rem !important;
        height: auto !important;
        color: $color__white;
        justify-content: unset;
        font-weight: $font-weight-semibold;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
        > span {
            font-size: 1.35rem !important;
        }

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--small !important;

            > span {
                font-size: $text__size--small !important;
            }
        }
    }

    .cashback--tile__button {
        margin: initial !important;
    }
}

.cashback--tile__progress {
    height: 2.5rem;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        height: 1.8rem;
        font-size: $text__size--smaller;
    }
}

.cash-back__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 3rem !important;
}

.cashback-tile__timer__container {
    width: fit-content;

    @media screen and (max-width: $break-point--1440) {
        font-size: 0.8rem;
    }
}