$shop-item-popup-info-currency-color: #21cae0c4;

.shop-item-info__container--item-info {
    .item-image {
        padding: 4rem 0 1rem 0;
        @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
            padding: 0;
            >img {
                max-height: 5em !important;
            }
        }
        @media only screen and (max-height: $break-point--360) and (orientation: landscape) {
            padding: 0;
            >img {
                max-height: 3.5em !important;
            }
        }
        >img {
            min-height: initial !important;
        }
    }
}

.shop-item-info__container--price {
    margin: .5rem 0 0 0 !important;
    .price {
        font-size: 2rem !important;
        @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
            font-size: 1.6rem !important;
        }
        .currency {
            font-size: inherit !important;
            color: $shop-item-popup-info-currency-color;
        }
    }
    @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
        margin: 0 !important;
    }
}

.with-price {
    .price {
        font-size: $text__size--large !important;
    }
}

.shop-item-info__container--text {
    @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
        font-size: 1.3rem;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.item--phrases__container {
    background-color: transparent;
}

.item--phrase {
    border: none !important;
    border-top: 1px solid #b234ff !important;
    background-color: #591ab7 !important;
    padding: 1rem !important;
    color: $color--white !important;
}