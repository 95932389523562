/********************************************
*            Custom bet styles skin         *
********************************************/

// Variables
$color__background: $main;
$bet__color: $color--chips;

// .custom-bet--host {
//     border-radius: $radius--default;
//     background-color: rgba(0, 0, 0, .26);
// }

// Dynamic bet
.custom-bet__container--bet-dynamic {
    text-shadow: 0 1px 0px rgb(0 0 0 / 40%);
    width: 100%;
    border-radius: 10rem;
    @media only screen and (max-width: $break-point--480 ) {
        width: 100%;
    }

    .custom-bet__container--bet-win-dynamic {
        &.bet {
            width: 100%;
        }
    }
}

.custom-bet__button {
    margin: 0.5rem;
    height: 5rem !important;
    width: 5rem !important;
    min-width: 5rem !important;
    @media only screen and (max-width: $break-point--480 ) {
        height: 4.5rem !important;
        width: 4.5rem !important;
        min-width: 4.5rem !important;
    }
}

.custom-bet__text--label-dynamic {
    display: none;
}

.custom-bet__text--numbers-dynamic {
    width: 100%;

    &.bet {
        width: 100%;
        flex-shrink: 0;
        font-size: 1.8rem;
        display: flex !important;
        flex-direction: column-reverse !important;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: $break-point--480 ) {
            min-width: 100% !important;
        }
    }

    .scroll-reward__label {
        @extend %direction-change;
        font-size: $text__size--large;
        text-shadow: 0 1px 0px rgb(0 0 0 / 40%);

        @media only screen and (max-width: $break-point--568 ) {
            font-size: $text__size--normal;
        }

        @media only screen and (max-width: $break-point--320 ) {
            font-size: 1.2rem;
        }

        &:after {
            content: none;
        }

        .scroll-reward__value {
            font-size: $text__size--large;

            @media only screen and (max-width: $break-point--568 ) {
                font-size: $text__size--normal;
            }
        }
    }

    .scroll-pool-name {
        @extend %direction-change;
    }
}