.menu--dropdown__elements--container {
    background-color: $color__white;
    border: none !important;
    box-shadow: 0 0 1.5rem .3rem #031e2472;
}

.menu--dropdown--item--wrapper-host {
    p {
        color: #2e3aff !important;
        text-decoration: none !important;
    }
    .bc__button--icon-cmp {
        fill: #2e3aff !important;
    }
}

.menu--dropdown__toggle--button--opened {
    color: #2e3aff !important;
}