.game-tile__wrapper {
    border-radius: 1.5rem;
    &.game-id__spadespairs {
        // box-shadow: inset 0 4px 0 #5f93b2, inset 0 -4px 0 #000f32, inset 2px 0 0 #005548, inset -2px 0 0 #005548;
        box-shadow: inset 0px 3px 3px #5f93b2,  inset -3px 0 2px rgb(0 65 35 / 60%), inset 3px 0 2px rgb(0 65 35 / 60%), inset 0 -3px 2px rgb(0 0 0 / 80%);
        background-image: url(imageUrl("lobby/spadesPairsBg.png"));
    }
    &.game-id__spadessolo {
        box-shadow: inset 0px 3px 3px rgba(211, 192, 255, 0.4), inset -3px 0 2px rgb(0 0 137 / 60%), inset 3px 0 2px rgb(0 0 137 / 60%), inset 0 -3px 2px rgb(0 0 0 / 80%);

        // box-shadow: inset 0 4px 0 #955eff, inset 0 -4px 0 #050505, inset 2px 0 0 #000062, inset -2px 0 0 #000062;
        background-image: url(imageUrl("lobby/spadesSoloBg.png"));
    }
}

.game-tile-title__img {
    display: none;
}

.game-tile__container {
    width: 100%;
    display: flex;
    height: 100%;
    margin-top: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.game-id__spadespairs {
        background-image: url(imageUrl("lobby/spadesPairs.png"));
    }
    &.game-id__spadessolo {
        background-image: url(imageUrl("lobby/spadesSolo.png"));
    }
}

.game-tile__logo {
    background-size: contain;
    width: 80%;
    height: 80%;
    margin: auto 0;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    @media screen and (max-width: $break-point--568) {
        right: 0;
        margin: auto 1rem;
        width: 60%;
    }
    @media screen and (max-width: $break-point--380) {
        right: 0;
        margin: auto 1rem;
        width: 60%;
    }
}
.game-tile__spades {
    height: 75%;
    width: 100%;
    background-position-x: right;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 18%;
    top: 25%;
    @media screen and (max-width: $break-point--680) {
        right: 0;
    }
    @media screen and (max-width: $break-point--380) {
        right: 0;
        top: 35%;
        height: 55%;
    }
}
