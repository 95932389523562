.link--format-button {
    color: #2e3aff;
    &:hover {
        color: #2e3aff !important;
    }
}

.container--single-message {
    .text--single-message {
        border: none !important;
        background-color: #abbcdf;
    }
    &.invite:not(.is-deleted) {
        .text--single-message {
            background-color: #abbcdf;
        }
    }
    &.kick {
        .text--single-message {
            background-color: #abbcdf;
        }
    }
}

.reverse-message {
    .text--single-message {
        border: none !important;
        background-color: #d1e6ff;
        
    }
    &.invite:not(.is-deleted) {
        .text--single-message {
            background-color: #d1e6ff;
        }
    }
    &.kick {
        .text--single-message {
            background-color: #d1e6ff;
        }
    }
}