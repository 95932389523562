$color-hover-cup: $container-dark-background-color;

.promo-tt__wrapper {
    border-radius: $container-border--radius;
}

.options-trigger {
    &:hover {
        background-color: $color-hover-cup;
    }
}

.promo-tt__container--placeholder {
    display: none;
}

.options-trigger {
    display: none;
}

.promo-tt__container--top-bar {
    display: none;
}

.promo-tt__restriction-cover {
    .restriction-level--host {
        @extend %promo-tile-info-container;
        font-weight: $font-weight-bold;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
    }
}

.promo-tt__play--button-wrapper {
    display: block;
    margin-bottom: 1rem;
}

.promo-tt__play--button {
    width: 40rem;
    max-width: 95%;
    height: 5rem !important;
}

.promo-tt__play--button-bclass {
    >p {
        font-size: $text__size--large  !important;

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--normal  !important;
        }
    }
}

.promo-tt_bottom-info {
    display: block;
    width: 100%;
    background: linear-gradient(#36393c, #000000);
    box-shadow: 0 0px 55px 65px #36393c;
}

.promo-tt__info--container {
    border-radius: $container-border--radius;
    font-weight: $font-weight-bold;
    display: block;
    background: linear-gradient(#53565c, #000000);
    padding: 0.75rem;
    border-top: 2px solid #61656b;
    width: 40rem;
    max-width: 95%;
    box-shadow: 0 2px 2px 0 $black__transparent--05;
    margin: 0 auto 1rem;
}

.promo-tt__info--content {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: center;
}

.promo-tt__info--content-prize {
    border-radius: $border--radius;
    width: 100%;
    height: 100%;
    background-color: $color__white;
    display: flex;

    .promo-tt__text--top-item-value {
        color: $color__black;
        padding: 0.5rem;
        font-size: 1.6rem;
    }
}

.promo-tt__info--content-prize-img {
    max-width: 5rem;
    max-height: 5rem;
}

.promo-tt__button--play {
    display: none !important;
}

.promo-tt__container--upper {
    display: none !important;
}

.promo-tt__info--content--timer--text,
.promo-tt__info--content--entry--text {
    font-size: $text__size--large  !important;
    text-transform: none;
    font-weight: $font-weight-normal;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal  !important;
    }
}

.promo-tt__info--content--timer--cmp-wrapper {
    display: flex;
    align-items: center;
}

.promo-tt__info--content--timer {
    display: flex;
    flex-flow: column;
    font-size: $text__size--x-large  !important;
    text-transform: none;
    font-weight: $font-weight-bold;
    margin: 0 0.5rem;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal  !important;
    }

    .time {
        color: $color__white;
        font-size: $text__size--x-large  !important;

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--normal  !important;
        }
    }
}

.promo-tt__info--content--entry {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.promo-tt__info--content--entry--wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.promo-tt__info--content--button {
    margin: 0 0.5rem;
    flex: 1;
    flex-shrink: 0;
    z-index: 1;
}

.promo-tt__info--content--timer--value {
    font-size: $text__size--x-large  !important;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
    color: $color__white;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal  !important;
    }
}

.promo-tt__container__description,
.promo-tt__leaderboard {
    display: none !important;
}

.promo-tt__container__name {
    .promo-tt__text--title {
        font-size: 4.8rem;
        line-height: 1;
        text-shadow: 0 1px 0 #a5b485, 0 3px 0 black, 0 5px 2px black;

        @media only screen and (max-width: $break-point--568) {
            font-size: 4rem;
            line-height: 1;
            font-size: 8.4vw !important
        }
    }
}

.promo-tt__info--content--timer--wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
}

.promo-tt__info--content--entry--value {
    @media only screen and (max-width: $break-point--568) {
        font-size: 1.6rem !important;
    }
}

.disabled {
    opacity: 0.5;
}

.promo-tt__info--content--invite--only {
    font-size: $text__size--x-large  !important;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
    color: $color__white;
    text-align: center;
    width: 100%;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--normal  !important;
    }
}