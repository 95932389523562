$color-tab-highlighted: #eaab00;
$color-tab-selected: #194098;

.club-page__top-tabs__scroll {
    background-color: #00346f;
}

.club-page__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.club-page__top-tabs__scroll {
    background-color: #00346f;
    .top-tabs__scrollbar {
        background-color: $color__chips;
    }
}

.club-page__list-item--navigation {
    width: 10rem;
}

.club-page__top-tabs__bclass {
    color: $color__white !important;
    background-color: transparent !important;
    width: 10rem;
    margin: 0;
    transition: none !important;
    &.top-tabs__selected {
        color: $color__chips !important;
        background-color: transparent !important;
    }
}

.club--page__button {
    margin: 1rem auto !important;
    width: 25rem;
    @media screen and (max-width: $break-point--380) {
        margin: .5rem auto !important;
    }
}

.club-page__top-tabs__tab {
    height: 100% !important;
}