$color-check-icon: #abdc28;
$color-current-reward: #a9dff4;
$container-bonus-background-color: #072f3d;

$color-day-reward-container-first-gradient-color: #206e80;
$color-day-reward-container-second-gradient-color: #072f3d;
$color-day-reward-container-box-shadow-color: #01111a;
$color-day-reward-container-upper-border-color: #01111a;
$color-day-reward-text: #ffa600;

.daily-bonus-box__container--day-container {
    transform: translateZ(0);
}

.day-container__title {
    color: $color__black;
    font-size: 1rem;
}

.daily-bonus-box__container--day-container.current-reward {
    box-shadow: 0 0 6px 3px $color-current-reward;
    border: none !important;
}

.day-container__reward {
    @include coloredContainer($color-day-reward-container-first-gradient-color,
        transparent, transparent,
        $color-day-reward-container-box-shadow-color,
        $color-day-reward-container-second-gradient-color);
    border-radius: 2rem;
    padding: 0.2rem 0.5rem;
    color: $color-day-reward-text;
}

.day-container__collected-overlay-wrapper {
    background: transparentize($container-bonus-background-color, .2);
}
