.gallery-preview__container--title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
    >p {
        height: initial !important;
        text-transform: uppercase;
    }
    color: #dbddf0;
}

.gallery-preview__container--image {
    &.with-hover {
      border: none !important;
      &:hover {
        border: none !important;
        transform: scale(1.05);
        box-shadow:0 4px 5px 0 #05061a78;
      }
    }
  }
  
.gallery-preview__wrapper {
  @include coloredContainer($container-dark-background-color,
    $container-dark-upper-border-color,
    $container-dark-inner-shadow-color,
    $container-dark-bottom-border-color);
}

.gallery-preview__image--gallery-add-photo__icon {
  color: #001d5e !important;
}

.gallery-photo__container--single-photo-wrapper {
  background-color: #2261a8;
}

.gallery-preview__image--gallery-add-photo {
  background-color: #2261a8 !important;
  &:hover {
    background-color: #9fcaf0;
    .gallery-preview__image--gallery-add-photo__icon {
      color: #9fcaf0 !important;
    }
  }
}