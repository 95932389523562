.restriction-level--host {
    background-color: transparent;
    font-size: .7em;

    @media only screen and (max-width: $break-point--768) {
        font-size: .6em;
    }
}

.restriction__level {
    line-height: 1.8rem;
    fill: inherit;
    color: inherit;
    text-transform: none !important;
    margin: 0 !important;
    word-break: break-word !important;
    text-align: center;

    .restriction__icon {
        margin: 0 0.1em 0 0;
    }
}
