$color-background: #f2f2fe;

.currency__baloon--wrapper {
    border: none;
    background-color: $color-background;
    border-radius: $container-border--radius;
}

.currency__baloon--arrow {
    &:after {
        border: none;
        border-radius: 0.2rem;
        background-color: $color-background !important;
    }
}

.currency__baloon--ratio {
    border: none;
    background-color: $color-background !important;
    background: linear-gradient(#fded0b,#f89312);
    font-weight: $font-weight-bold !important;
    box-shadow: 0 3px 2px 0px rgb(157 51 158 / 40%);
    text-shadow: 0 2px 0 #fcd60b;
}
