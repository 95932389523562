.promo-tournaments-options__tabs-style {
    color: $color__white;
    height: 3rem;

    > p {
        font-size: 1.4rem !important;
        color: $color__white;
    }

    &.selected {
        color: $color-tab-selected;

        > p {
            color: $color-tab-selected;
        }
    }

    &.highlighted {
        color: $color-tab-highlighted !important;

        > p {
            color: $color-tab-highlighted !important;
        }
    }

    .bc__button--icon-cmp {
        display: none !important;
    }
}

.promo-tournaments-options__top-tabs__scroll {
    background-color: #00346f;
}

.promo-tournaments-options__top-tabs__scrollbar {
    background-color: #9fcaf0;
}

.promo--tournaments__top-tabs__list-item--navigation {
    height: 3rem !important;
}

.promo-tournaments-options__top-tabs__bclass {
    color: #00346f !important;
    background-color: transparent !important;
    width: 10rem;
    margin: 0;
    transition: none !important;
    font-size: 2rem;
    &.top-tabs__selected {
        color: #9fcaf0 !important;
        background-color: transparent !important;

        > p {
            color: $color-tab-selected !important;
        }
    }
}

.promo-tournaments-options__top-tabs__container {
    background-color: transparent !important;
}