.custom-blitz-winners-info__container {
    background-color: #2161a7;
    border: none !important;
    padding: 0 !important;
    border-radius: 1.6rem;
    border-top: 1px solid #2161a7 !important;
    width: calc(100% - 3rem) !important;
}

.custom-blitz-winners-info--header {
    padding: 1rem;
    font-weight: $font-weight-extra-bold;
    background-color: #001d5e;
    font-size: $text__size--large;
    font-style: italic;
}

.custom-blitz-winners-info__tournament-name {
    font-size: $text__size--x-large;
    padding: 1.5rem 3rem !important;
}

.custom-blitz-winner-info__prize-pool__label {
    font-size: $text__size--normal;
    font-weight: $font-weight-semibold;
    color: #2e89ed;
}

.custom-blitz-winner-info__prize-pool__currency {
    font-size: 2rem !important;
}

.custom-blitz-winners__box {
    width: calc(100% - 8rem);
    margin: 3rem 4rem !important;
    @media screen and (max-width: $break-point--1440) {
        width: calc(100% - 4rem);
        margin: 3rem 2rem !important;
    }
}
