.shop-promo__item-content {
    border-radius: $radius--default;
}

.shop-promo__item {
    width: 60%;
    padding-bottom: 20%;
    @media screen and (max-width: $break-point--768) {
        width: 90%;
        padding-bottom: 30%;
    }
}