.club--notification--avatar__container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.club__notification-result__container {
    border: none !important;
    .club__notification-result__label {
        text-shadow: none !important;
    }
}