.ranked-games-leaderboard-list__container {
    @include ranked-tiles-colors((border-color: #2a056c, bg-color: #23055b, inner-padding: 0, height: 100%));
    border-radius: 0 0 1.5rem 1.5rem;
}

.ranked-games-leaderboard-list__title-text {
    color: #d6daff;
    text-shadow: 0 -1px 0 #fafeff, 0 1px 0px #8775f7, 0 2px 0px #000;
}

.ranked-games-leaderboard-list__title {
    border-radius: 10rem;
    box-shadow: 0 -6px 10px 1px #420ca7;
    background: linear-gradient(#15033d, #23055b);
}

.ranked-games-leaderboard-list__item {
    color: #ff67f2;
    text-shadow: 0 1px 0px #000;
}