.home__custom-select--lang {
    color: $white__transparent--05;
    cursor: pointer;
    width: fit-content;
    background-color: rgba(0,0,0,.3);
    border-bottom: 1px solid #4e8dd4;
    border-radius: 10rem;
}

.home__custom-select__label {
    color: $color--white !important;
}

.home__custom-select__expand--color {
    color: $color--white !important;
}

.home__custom-select__option {
    background-color: transparent;
    > p {
        color: $popup-font--color;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.home__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-font--color;
            font-size: $text__size--normal;
            text-decoration: none;
        }
    }
    &:hover {
        background-color: #edf0f1;
        > p {
            color: #194098;
        }
    }
}

.home__image--logo {
    background-size: 70% 100% !important;
} 