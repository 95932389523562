%toggle-round {
    content: "";
    position: absolute;
    display: block;
    margin: 0 !important;
}

.cmn-toggle {
    background: none;
    background-color: #d3d6e5;
    box-shadow: inset .2rem .2rem .3rem 0 $black__transparent--02;
    position: relative;
    width: 5.4rem !important;
    height: 3rem !important;
    &:before {
        @extend %toggle-round;
    }
    .cmn-toggle-round {
        @extend %toggle-round;
        width: 2.5rem !important;
        height: 2.5rem !important;
        background-color: $color__white;
        border: 0 !important;
        box-shadow: 0.1rem 0.2rem 1rem 0.1rem $black__transparent--02;
    }
}

.cmn-toggle {
    &.checkedToggle {
        background-color: #194098;
        box-shadow: inset .2rem .2rem .3rem 0 rgb(42 86 170);
        .cmn-toggle-round {
            background-color: $color__white;
            border: 0 !important;
            box-shadow: -0.8rem 0.2rem 1rem 0.1rem $black__transparent--02;
            transform: translateX(100%) !important;
        }
    }
}

.toggle-button__checkbox {
    background-color: $color__white;
    border: .1rem solid $light-accent-2;
    border-radius: .2rem;
    &.toogle-button__checked { 
        background-color: $light-accent-2;
    }
}