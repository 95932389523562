.top-bar__container--notification {
    &.premium-gift {
        border: none;
    }
}

.top-bar__container--player-avatar {
    .username-container {
        border: none;
    }
}