.form-dropdown__custom-select {
    color: $white__transparent--05;
    cursor: pointer;
    border-radius: $radius--default;
    height: 5rem;
    width: 25rem;
    margin: 0.3rem auto .5rem;
    padding: 0 0.5rem;
    background-color: rgba(0,0,0,.3);
    border-bottom: 1px solid #4e8dd4;
    border-radius: 10rem;
}

.form-dropdown__custom-select__option {
    background-color: transparent;
    height: 3rem;
    padding: 1rem;
    > p {
        font-size: $text__size--normal;
        text-decoration: none;
        color: $popup-font--color;
    }

    &.form-dropdown__custom-select__option__selected {
        background-color: transparent;
        height: 3rem;
        padding: 1rem;
        > p {
            font-size: $text__size--normal;
            text-decoration: none;
            color: $popup-font--color;
        }
    }
    &:hover {
        background-color: #edf0f1;
        > p {
            color: #194098;
        }
    }
}

.form-dropdown__selected-option {
    color: $color--white;
}

.form-dropdown__custom-select__extend--button {
    color: $color--white !important;
}
