$border-width: 7px;
$border-width-mobile: 5px;
$color-completed: #bbdb4f;
$color-text-selected: #6b1b6b;
$color-value-selected: #6b1b6b;
$color-selected: #f2f2fe;
$color-tick-border: #c560c6;
$color-next: #6b1b6b;
$color-text-next: #e36ee5;

.game__index-wrapper {
    border: none;
    background-color: $color-next;

    .game__index--text, .game__index--value {
        color: $color-text-next;
    }

    &.selected {
        background-color: $color-selected;
        box-shadow: 0px 2.5px 0px 0px $color__white inset, 0px 2px 0 0 $color-text-selected, 0 9px 15px 3px #942a92;

        .game__index--text, .game__index--value {
            color: $color-text-selected;
        }
    }

    &.completed {
        background: linear-gradient(#c3fd00, #7ecb02);
        box-shadow: 0px 2px 0px 0px #f9f66b inset, 0px 2px 0 0 $color-text-selected, 0 4px 4px 0px #942a92;

        .game__index--text, .game__index--value {
            color: #293d00;
            text-shadow: 0 0 0 #ffff36, 0.5px 0 0 #ffff36, 0 1px 0 #ffff36, 0.5px 1px 0 #ffff36;
        }
    }
}

.game__index--value {
    font-style: italic;
    font-size: 3.5rem !important;
}

.game__index--icon-wrapper {
    left: 50%;
    right: initial;
    background-color: $color-completed;
    border: 4px solid $color-tick-border;
    color: $color-text-selected;
    transform: scale(0) translate(-50%, -50%) !important;

    &.completed {
        transform: scale(1) translate(-50%, -50%) !important;
    }
}

.game__index--icon {
    border-radius: 50%;
    box-shadow: 0px 2px 0px 0px #f9f66b inset;
}
