$bg-color: #6b1b6b;
$completed-color: #bbdb4f;
$currency-baloon-text-color: #6b1b6b;

.currency__baloon {
    color: $currency-baloon-text-color !important;
    text-transform: uppercase !important;
}

.currency__baloon-cclass {
    box-shadow: 0 9px 15px 3px #942a92;
}

.currency__baloon--text-config-bclass {
    color: $currency-baloon-text-color !important;
    font-weight: $font-weight-bold !important;
    text-transform: uppercase !important;
}

.currency__baloon--value-config-bclass {
    color: $currency-baloon-text-color !important;
    font-weight: $font-weight-extra-bold !important;
}

.currency__baloon--item-container-config-bclass {
    margin: 0 !important;
}

.currency__baloon--value-config-icon-bclass {
    color: $currency-baloon-text-color !important;
}

.games__progress--game-line {
    border: none;
    background-color: $bg-color;
    transition: border-color ease 0.4s, background-color ease 0.4s;

    &.completed {
        background-color: $completed-color;
        border-color: $completed-color;
    }
}

.games__progress--game-line-completed {
    border: 2px solid $completed-color;
    background-color: $completed-color;
}

.games__progress--line-wrapper {
    height: 4px !important;
}


.games__progress--game-index {
    &.current {
        z-index: 0 !important;
        position: absolute;
        pointer-events: none;
        transform: scale(2.8);
        opacity: 0.1;
    }
}