$sii__chip-icon-color: #072f3d;

.shop-item-info__value--container {
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 3rem !important;
    @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
        font-size: 1.8rem !important;
    }
    .sii__chip-cmp {
        font-size: inherit !important;
    }
    >span {
        margin: 0 .5rem;
    }
}

.shop-item-info__container--bonus {
    display: flex !important;
    flex-flow: row wrap;
    align-items: baseline;
    margin-top: 0.5rem;
    .chip-icon-component {
        font-size: 2rem !important;
    }

    .shop-item-info__text--bonus-vip {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        >img {
            height: 2rem;
            margin: 0 .2rem 0 .3rem;
        }
        font-size: 1.5rem;
        font-weight: $font-weight-bold;
        background: linear-gradient($color-vip-value-first, $color-vip-value-second);
        background-clip: text;
        color: transparent;
        >span {
            margin-top: .5rem;
        }
    }
}

.sii__chip-cmp {
    fill: $sii__chip-icon-color;
}

.shop-item-info__container--badge {
    .duration__container {
        .duration {
            margin-left: initial !important;
            &:before {
                content: none !important;
            }
        }
    }
}

.shop-item-info__promo {
    height: 2rem;
}
