$lobby-empty-space: 200px;

.home-lobby__container--outer {
    @include scrol-container();
    @include scrollbar(0.2rem, 0.2rem, #8a48c4);
    height: calc(100% - 14.5rem);
    max-width: $max-content-width + $lobby-empty-space;

    @media screen and (max-width: $break-point--768) {
        height: calc(100% - 12rem);
    }

    @media only screen and (max-width: $break-point--1440) {
        max-width: $max-content-width-1440 + $lobby-empty-space;
    }

    &.wide {
        max-width: 1600px !important; // TODO: hack - this is the full lobby width

        .home-lobby__container--inner {
            max-width: 1600px !important; // TODO: hack - this is the full lobby width
        }
    }
}

.home-lobby__container--inner {
    max-width: $max-content-width;
    height: 100%;
    margin: auto;

    @media only screen and (max-width: $break-point--1440) {
        max-width: $max-content-width-1440;
    }
}
