.item-buy-button__main {
    .item-buy-button__text {
        color: inherit !important;
        font-weight: inherit;
        &:not(.price) {
            @media screen and (max-width: $break-point--568) {
                font-size: $text__size--normal;
            }
        }
    }

    .chips {
        color: inherit !important;
        font-weight: inherit !important;
        fill: inherit !important;
    }

    .gems {
        color: inherit !important;
        font-weight: inherit !important;
    }
}