.progress-bar__values--container {
    color: #9fcaf0;
}

.progress-bar__progress--partition {
    border: none !important;
    background-color: #1a5290;
    &.progress-classic {
        background-color: #1a5290 !important;

    }
}

.progress-bar__progress--container {
    border: none !important;

    &.progress-classic {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $accent--1;
            }

            &:nth-last-child(2) {
                background-image: none !important;
                animation: none !important;
                background-color: #1a5290 !important;
            }
        }
    }

    &.progress-good-bad-empty {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $accent--1;
            }

            &:nth-last-child(2) {
                background-color: #ff084c;
            }

            &:nth-last-child(3) {
                background-color: #1a5290 !important;
            }
        }
    }

    &.progress-multiple {
        :not(.overrided) {
            &:nth-last-child(1) {
                background-color: $active--color;
            }

            &:nth-last-child(2) {
                background-color: #ff084c;
            }

            &:nth-last-child(3) {
                background-color: blue;
            }

            &:nth-last-child(4) {
                background-color: yellow;
            }

            &:nth-last-child(5) {
                background-color: deeppink;
            }

            &:nth-last-child(6) {
                background-color: cyan;
            }
        }
    }
}