.profile__container--user-content,
.user-info__container--user-info,
.profile__container--club,
.profile__container--images {
    padding: 0 !important;
}

.profile__container--club--title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001d5e;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
}

.user-info__text--username {
    &:not( .online ) {
        &:before {
            background-color: #444666;
        }
    }
}

.profile__container--images {
    margin: .5rem 0 !important;
}

.user-info__container--other-info {
    background-color: #000c288c !important;
}

.profile__container--club {
    margin: 1rem 0;
}
