/****************************
*      Button component        *
****************************/

.bc__button {
  @extend %button;
}

.bc__button.bc__close {
  @include button--icon('', $button--transparent);
}

@each $attributes in $button-modifiers {
  $key: nth($attributes, 1);
  .#{$button-slug}__button--text-#{$key} {
    @include button--text($key);
  }

  .#{$button-slug}__button--image-#{$key} {
    @include button--image($key);
  }

  .#{$button-slug}__button--icon-#{$key} {
    @include button--icon('', $key);
  }

  .#{$button-slug}__button--image-text-#{$key} {
    @include button--image-text($key);
  }

  .#{$button-slug}__button--icon-text-#{$key} {
    @include button--icon-text('', $key);
  }
}

.bc__button--flat {
  border: none;
  &:hover, &:active {
    border: none !important;
  }
  &:before {
    display: none;
  }
  &:disabled {
    border: none !important;
  }
}

.bc__spinner--cmp {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 1em !important;
    font-size: inherit !important;
}