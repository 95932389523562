.ranked-team-stats__container--game-stats {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.team-stats__container--replay {
    &:not(.rematch-disabled) {
        &.hasLeft {
            .leaved-icon {
                font-size: 5rem !important;
            }
        }
    }
}

.team-stats__button--replay {
    border: none !important;
    background-color: transparent !important;
}

.team-stats__button--replay {
    &.old-design {
        display: none;
    }
    &.new-design {
        display: block;
    }
}

.ranked-team-stats--wrapper {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}