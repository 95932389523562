$color-shop-text-highlighted: #eaab00;

.shop__container--text {
    margin: 1rem 0;
    font-weight: $font-weight-bold !important;
    text-transform: uppercase !important;
    color: $color__white !important;
    font-size: $text__size--large !important;
    .title,
    .shop__text--colored,
    .shop__title--value {
        font-size: inherit !important;
        color: inherit !important;
        font-weight: inherit !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    .shop__text--colored {
        span {
            color: $color-shop-text-highlighted;
        }
    }
}