.rankings-scroll-tile__title-container {
    display: flex !important;
    > p {
        font-size: 1.6rem;
    }
}

.rankings-scroll-tile__wrapper {
    background-image: url(imageUrl("lobby/matchmaking_transparent.png"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent !important;
}

.rankings-scroll-tile-bclass {
    height: 12.5rem !important;
    width: 9.5rem !important;
    @media screen and (max-width: $break-point--1024) {
        height: 12.5rem !important;
        width: 9.5rem !important;
    }
    @media screen and (max-width: $break-point--480) {
        height: 11.5rem !important;
        width: 8.5rem !important;
    }
}
