.gift-lists__container {
    &:not(.in-game) {
        padding: 1rem;
    }
}

.gifts-tab__text--premium-gifts-label {
    color: #a6aeff;
    font-weight: $font-weight-semibold;
}

.gift-list__container {
    &.premium {
        margin-bottom: 2rem;
    }
}