.custom-blitz-winner-box__container {
    background-color: transparent;
    &.first {
        border: 1px solid $color__chips;
    }

    &.second {
        border: 1px solid #9cb4b8;
    }

    &.host {
        border: 1px solid #2e89ed;
    }
}

.custom-blitz-winner-box__place--label {
    > span {
        transform: translateY(-100%);
        padding: 0 3rem;
        background-color: #2161a7;
    }

    &.first {
        color: $color__chips !important;
    }

    &.second {
        color: #9cb4b8 !important;
    }

    &.host {
        color: #2e89ed !important;
    }
}

.custom-blitz-winner-box__player--reward__label {
    color: #2e89ed !important;
    font-weight: $font-weight-semibold;
}