.club-info-footer__text--tooltip {
    color: $color__black;
    padding: 1rem;
}

.club-info-footer__club-privacy, .club-info-footer__club-activity {
    color: $color__black;
}

.club-info-footer__container--item {
    background-color: $black__transparent--03;
    border-radius: 0.8rem !important;
}