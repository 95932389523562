.profile-large-button__wrapper {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
    margin: 0.5rem 0 !important;
}

.large-button__button {
    flex: .5 !important;
}

.large-button__image-container {
    flex: .3 !important;
}