$color-tab-highlighted: #eaab00;
$color-tab-selected: #48d6ff;

.shop__tabs {
    display: none !important;
}

.shop__tabs-style {
    color: $color__white;
    height: 3rem;

    > p {
        font-size: 1.4rem !important;
        color: $color__white;
    }

    &.selected {
        color: $color-tab-selected;

        > p {
            color: $color-tab-selected;
        }
    }

    &.highlighted {
        color: $color-tab-highlighted !important;

        > p {
            color: $color-tab-highlighted !important;
        }
    }

    .top-tabs__list-item--navigation {
        width: auto !important;
        padding: 0 1rem;
    }

    .bc__button--icon-cmp {
        display: none !important;
    }
}

.shop__top-tabs__scroll {
    background-color: #00346f;
    visibility: visible !important;
}

.shop__top-tabs__scrollbar {
    background-color: $color__chips;
    visibility: visible !important;
}

.shop__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    margin: 0;
    transition: none !important;
    > p {
        text-decoration: none;
    }
    &.shop__top-tabs__bclass__selected {
        background-color: transparent !important;
        > p {
            color: $color__chips !important;
        }
    }
}

.shop__tabs--redesign {
    .tab {
        padding: 0 !important;
    }
}