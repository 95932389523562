.training-scroll-tile__title {
    font-weight: 800;
    color: #1e1510;
}

.training-scroll-tile__room-separator {
    width: 75% !important;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.training-scroll-tile__room-separator__svg {
    width: 100% !important;
    color: #898480;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.training-scroll-tile__bclass {
    height: 4.5rem !important;
    >p {
        color: #542b00 !important;
        text-shadow: 0 4px 3px #ff7a00 !important;
    }
 }

.training-scroll-tile__description {
    color: #1e1510;
    z-index: 1;
}

.training-scroll-tile__container {
    background: #d0d0d0;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 3px 2px #e6d6c5,inset 0 -3px 2px #3d3731,inset 3px 0 2px #6a635c,inset -3px 0 2px #6a635c;
    border-radius: $container-border--radius;
}

.training-scroll-tile__logo {
    background-size: cover;
    background: url(imageUrl("lobby/spades-cards.png"));
    background-position: center;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.training-scroll-tile__button {
    width: 27rem !important;
    margin-bottom: 3.5rem !important;

    @media screen and (max-height: $break-point--1280) {
        margin-bottom: 2rem !important;
    }

    @media screen and (max-height: $break-point--768) {
        margin-bottom: 2.5rem !important;
    }
}

.training-scroll-tile__icon {
    display: none !important;
}

.training-scroll-tile__button_text {
    text-shadow: 0 4px 3px #ff7a00 !important;
}

.training-scroll-tile__title_container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.training-scroll-tile__room-transactions {
    color: #1e1510;
}

.training-scroll-tile__room-transactions {
    position: absolute;
    bottom: 0;
}

.training-scroll-tile-reward__label {
    display: flex;
    flex-direction: row;
}

.training-scroll-tile-reward__value-label {
    margin-right: 0.5rem;
}

.training-scroll-tile__players-counter {
    padding: .5rem !important;
    background-color: #00000098 !important;
    color: $color--white;
    border-radius: 10rem;
    width: 7rem;
    left: 50%;
    transform: translateX(-50%);
}