.offer-buy-item--container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.offer-buy-item-container--host {
    border-radius: $container-border--radius;
}

.offer-item-shine-cmp {
    border-radius: $container-border--radius;
}