.hidden__shop__search--bar {
    box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
    background: rgba(0,0,0,.3);
    border: none;
    border-bottom: 1px solid #4e8dd4;
    &:active, &:focus {
      box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
      background: rgba(0,0,0,.3);
      border: none;
      border-bottom: 1px solid #4e8dd4;
    }
}

.hidden-shop__selector-item {
    background-color: $black__transparent--03;
    border: none;

    &.selected {
        background-color: $black__transparent--07;
    }
}

.hidden__shop--list--users-box {
    margin-top: 1rem;
}