$color-tab-selected: #7190f2;

.gift-shop-drawer__top-tabs__scroll {
    background-color: #2850d1;
}

.gift-shop-drawer__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.gifts-shop-drawer__wrapper {
    background-color: transparent !important;
}

.gift-shop-drawer-button-icon-tab__selected {
    color: $color-tab-selected;
    > p {
        color: $color-tab-selected;
    }
}

.gift-shop-drawer-button-icon-tab__bclass {
    transition: none !important;
    background-color: transparent !important;
    color: #2850d1;
    > p {
        color: #2850d1;
    }
    &.gift-shop-drawer-button-icon-tab__selected {
        background-color: transparent !important;
        color: #7190f2;
        > p {
            color: #7190f2;
        }
    }
}

.gifts-shop-drawer__container--content {
    height: calc(100% - 3rem) !important;
    &.one-tab {
        height: calc(100% - 2rem) !important;
    }
    .content-gifts {
        margin-top: .5rem !important;
        height: calc(100% - 2.5rem) !important;
        margin-top: 1rem;
        @media only screen and (max-width: $break-point--768) {
          height: calc(100% - 1.5rem) !important;
        }
        .content-wrapper {
            padding: 0 2rem !important;
            background-color: transparent !important;
        }
        &.one-tab {
            height: 100% !important;
        }
    }
}