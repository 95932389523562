.social__searchbar-input {
  box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
  background: rgba(0,0,0,.3);
  border: none;
  border-bottom: 1px solid #4e8dd4;
  &:active, &:focus {
    box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
    background: rgba(0,0,0,.3);
    border: none;
    border-bottom: 1px solid #4e8dd4;
  }
}

.social__container {
  @include scrollbar(0.2rem, 0.2rem, #8a48c4);
}

.social-layout__avatar {
  width: calc(9% - .4rem) !important;

  @media only screen and (min-height: $break-point--1024) {
    width: calc(11.1% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--1440) {
    width: calc(10% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--1080) {
    width: calc(10% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--1080) and (min-height: $break-point--1080) and (orientation: portrait) {
    width: calc(12.2% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--768) {
    width: calc(11.1% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--680) {
    width: calc(14.2% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--440) {
    width: calc(16.666% - .4rem) !important;
  }

  @media only screen and (max-width: $break-point--360) {
    width: calc(20% - .4rem) !important;
  }
}