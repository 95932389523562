$leader-last-seen-color: #f5a724;

.club--members--list__label-last-seen {
    color: $leader-last-seen-color;
}

.club--members--list__label {
    color: #8a48c4;
    font-weight: $font-weight-semibold;
}

.club--members--dropdown--btn {
    &:hover {
        color: #2e3aff;    
    }
}

.club--members--list__list--box {
    margin: 0 !important;
    padding: 0 2rem;
}