.level-up-progress-bar--container {
    background-color: #003772;
    box-shadow: inset 0 0 5px 2px rgba(0,0,0,.5), 0 0 1px 2px #57b5c2;
}

.level-up-progress-bar--secondary-wrapper {
    background-color: #0067aa;
}

.level-up-progress-bar--initial-wrapper {
    background-color: #092e60;
    box-shadow: 0 0 1px 1px #2572a5
}