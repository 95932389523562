.restriction__container {
    > * {
        margin: 0;
        padding: 1rem 0.25rem;
        @media only screen and (max-width: $break-point--768) {
            padding: 0.25rem;
        }
    }
}

.restriction-vip--host {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}