$daily-bonus-tile-content-container-bg: url(imageUrl("lobby/DailyReward_1.png"));

.daily-bonus--tile__content--container {
    --daily-bonus-tile-content-container-bg: #{$daily-bonus-tile-content-container-bg};
    border-radius: $border--radius;
    background: var(--daily-bonus-tile-content-container-bg) no-repeat center bottom, linear-gradient(#272b54, #181b3f);
    box-shadow: inset 0 3px 2px #c2ceff80, inset 0 -3px 2px #2b008e, inset 3px 0 2px #0e0071e3, inset -3px 0 2px #0e0071e3;
    background-size: cover;

    &.progressive-videos {
        border-radius: $border--radius;
        box-shadow: inset 0 3px 2px #c2ceff80, inset 0 -3px 2px #2b008e, inset 3px 0 2px #0e0071e3, inset -3px 0 2px #0e0071e3;
        background: var(--daily-bonus-tile-content-container-bg) no-repeat center bottom, linear-gradient(#272b54, #181b3f);
        background-size: cover;
    }
}

.daily-bonus--tile__text {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    height: auto !important;
}

.daily-bonus--tile__text--container {
    position: absolute;
    display: flex !important;
    flex-direction: column !important;
    width: fit-content;
    height: 100% !important;
    max-width: 100%;
    text-transform: uppercase;
    background-color: transparent !important;

    &.daily-bonus--tile__extra-small-tile {
        align-items: flex-start;
        justify-content: space-evenly !important;
        padding: .5rem 1rem !important;
        .daily-bonus--tile__text,
        .daily-bonus--tile__text--name {
            font-size: 3rem !important;
            line-height: 2.4rem !important;
            text-align: left !important;
            @media screen and (max-width: $break-point--568) {
                font-size: 2.6rem !important;
            }
            @media screen and (max-width: $break-point--380) {
                font-size: 2.2rem !important;
            }
        }
    }

    &.daily-bonus--tile__small-tile {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-content: center !important;
        padding: 0 !important;
        .daily-bonus--tile__text {
            max-width: 90%;
        }
        .daily-bonus--tile__text,
        .daily-bonus--tile__text--name {
            font-size: 5rem !important;
            line-height: initial !important;
            margin-bottom: 1rem;
            text-align: center !important;
            justify-content: center;
            @media screen and (max-width: $break-point--680) {
                font-size: 3.5rem !important;
            }
            @media screen and (max-width :$break-point--440) {
                font-size: 2.5rem !important;
            }
            @media screen and (max-width: $break-point--320) {
                font-size: 2rem !important;
            }
        }

        .daily-bonus--tile__button {
            height: 3.5rem !important;
            @media screen and (max-width: $break-point--680) {
                height: 3rem !important;
                min-width: 10rem !important;
            }
        }

        .daily-bonus--tile__button-bclass {
            border-radius: 2rem !important;
        }

        .daily-bonus--tile__timer {
            color: #fff;
        }
    }

    .daily-bonus--tile__text,
    .daily-bonus--tile__text--name {
        color: #e6e6e6;
        text-shadow: 1px 1px 2px #171175;
        font-weight: $font-weight-extra-bold !important;

        &.progressive-videos {
            text-shadow: 1px 1px 2px #171175;
        }
    }

    .daily-bonus--tile__timer {
        display: initial !important;
        text-align: initial !important;
        font-size: 1.5rem !important;
        height: auto !important;
        font-weight: $font-weight-semibold;
        >span {
            font-size: 1.35rem !important;
        }
    }

    .daily-bonus--tile__button {
        margin: initial !important;
    }
}

.daily-bonus-tile__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.daily-bonus--tile__text__bonus-ready {
    display: flex;
}

.daily-bonus--tile__text--container {
    height: 100%;
}