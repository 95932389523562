.tile__wrapper {
    &.highlighted {
        border: none;
        overflow: unset !important;
    }
    background: none !important;
}

.tile-counter {
    display: block;
}

.tile-counter.knockout3--tile,
.tile-counter.royal8--tile,
.tile-counter.royal16--tile {
    right: 1.5rem;
    top: 1.5rem;
}

.counterContainer.events--tile {
    background-color: rgb(0 18 30 / 25%)!important;
}

.counterContainer.navigation--tile.custom--tile {
    background-color: rgb(0 18 30 / 25%)!important;
}

.counterContainer.bettingwheel--tile {
    background-color: rgb(0 18 30 / 25%)!important;
}

.tile-counter.royal16--tile,
.tile-counter.knockoutTarot--tile {
    top: 1.5rem;
    right: 1.5rem;
}

.counterContainer.royal16--tile {
    @extend %royal16-tile-info-container;
}

.counterContainer.royal8--tile {
    @extend %royal16-tile-info-container;
}

.counterContainer.knockoutTarot--tile {
    @extend %knockout-tile-info-container;
}

.counterContainer.knockout3--tile {
    @extend %knockout-tile-info-container;
}

%royal16-tile-info-container {
    background-color: #45ccf8;
    box-shadow: 0 -2px 2px 0 #48d1fa inset, 0 -2px 6px 0 #90ffff, 0 3px 3px 0 #0566d1;
    padding: 0.8rem 2.5rem;
    font-size: 1.4rem;

    @media only screen and (max-width: $break-point--768) {
        padding: .5rem 2rem;
    }
}

%knockout-tile-info-container {
    background-color: #e46fe6;
    box-shadow: 0 -2px 2px 0 #c954ca inset, 0 -2px 6px 0 #ea87ec, 0 3px 3px 0 #a327a0;
    padding: 0.8rem 2.5rem;
    font-size: 1.4rem;

    @media only screen and (max-width: $break-point--768) {
        padding: .5rem 2rem;
    }
}

%promo-tile-info-container {
    background-color: $black__transparent--05;
    padding: 0.8rem 2.5rem;
    font-size: 1.4rem;

    @media only screen and (max-width: $break-point--768) {
        padding: .5rem 2rem;
    }
}