.casino--room__wrapper {
    box-shadow: inset 0px 3px 3px rgb(255 255 255 / 35%);
    border-bottom: .2rem solid #114940;
    border-radius: $border--radius;
    background-image: url(imageUrl("lobby/BettingWheel.png"));
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.casino--room__text--container {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: 100% !important;
    max-width: 100%;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    text-transform: uppercase;
    background-color: transparent !important;
    padding: 0.5rem 1rem !important;

    .casino--room__text,
    .casino--room__text--name {
        font-size: 3rem !important;
        text-shadow: 0 1px 0px #84bab0, 0 2.5px 0px #00ffe1, 0 3px 0px #0aa179, 0 9px 12px #0aa179;
        line-height: 2.4rem !important;
        text-align: initial !important;
        font-weight: $font-weight-extra-bold !important;
        @media screen and (max-width: $break-point--568) {
            font-size: 2.6rem !important;
        }
        @media screen and (max-width: $break-point--380) {
            font-size: 2.2rem !important;
        }
    }
}

.casino--room__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 3rem !important;
}

.casino--room__text {
    justify-content: flex-start !important;
}