.game-summary__container--team-won {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.game-summary__popup {
    background-color: #2261a8 !important;
}

.game-summary__text--won {
    font-weight: $font-weight-semibold;
    color: #8a48c4;
}