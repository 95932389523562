.ranked-games-leaderboard-info-box {
    box-shadow: 0 2px 2px 0px #421498;
    border-radius: $border--radius;
    border: initial;
}

.ranked-games-leaderboard-info-box__inner-glow {
    box-shadow: inset 0px 3px 4px 0px $black__transparent--07;
}

.ranked-games-leaderboard-info-box__title--text {
    color: #8a78fc;
    text-shadow: 0 1px 0px #000;
    font-weight: $font-weight-bold;
}

.ranked-games-leaderboard-info-box__title {
    background-color: #1b1044;
    text-transform: uppercase;
}

.ranked-games-leaderboard-info-box__delimiter {
    background-color: #120a2e;
    height: 2px;
    display: block;

    &:after {
        content: "";
        background-color: #2f1e67;
        width: 100%;
        display: block;
        height: 1px;
        margin-top: 1px;
    }
}

.ranked-games-leaderboard-info-box__value {
    background-color: #221451;
    text-shadow: 0 3px 3px #000;
    color: white;
}
