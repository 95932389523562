.shop-chips__wrapper {
    @media only screen and (max-width: $break-point--380) {
        max-width: initial !important;
    }
}

.shop-chips__box {
    display: none !important;
}

.shop-chips__container--items {
    max-width: 45rem !important;
}