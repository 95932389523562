@mixin glory($type) {
    .lobby__professional--avatar-container {
        position: relative;
        margin: 0;

        &:nth-of-type(1) {
            margin-top: 0;
            order: 2;

            @if $type ==$large {
                width: 12rem;
                height: 15rem;
            }

            @else if $type ==$medium {
                width: 10.5rem;
                height: 13.5rem;

                .lobby__professionals--badge-container {
                    .lobby__professionals--username {
                        font-size: $text__size--small;
                    }
                }
            }

            @else if $type ==$small {
                width: 8rem;
                height: 11rem;

                .lobby__professionals--badge-container {
                    .lobby__professionals--username {
                        font-size: $text__size--smaller;
                    }
                }
            }

            .lobby__professionals--badge-container {
                @include frame-image($badge-gold );
                background-size: 100% 100%;
            }
        }

        &:nth-of-type(2) {
            order: 1;
            margin-top: .5rem;

            @if $type ==$large {
                width: 11rem;
                height: 14rem;
            }

            @else if $type ==$medium {
                width: 9.5rem;
                height: 12.5rem;

                .lobby__professionals--badge-container {
                    height: 38%;

                    .lobby__professionals--username {
                        font-size: $text__size--small;
                    }
                }
            }

            @else if $type ==$small {
                width: 7rem;
                height: 10rem;

                .lobby__professionals--badge-container {
                    height: 40%;

                    .lobby__professionals--username {
                        font-size: $text__size--smaller;
                    }
                }
            }

            .lobby__professionals--badge-container {
                @include frame-image($badge-silver );
            }
        }

        &:nth-of-type(3) {
            order: 3;
            margin-top: .5rem;

            @if $type ==$large {
                width: 11rem;
                height: 14rem;
            }

            @else if $type ==$medium {
                width: 9.5rem;
                height: 12.5rem;

                .lobby__professionals--badge-container {
                    height: 40%;

                    .lobby__professionals--username {
                        font-size: $text__size--small;
                    }
                }
            }

            @else if $type ==$small {
                width: 7rem;
                height: 10rem;

                .lobby__professionals--badge-container {
                    .lobby__professionals--username {
                        font-size: $text__size--smaller;
                    }
                }
            }

            .lobby__professionals--badge-container {
                @include frame-image($badge-bronse );
            }
        }

        .lobby__professionals--avatar-frame {
            position: absolute;
            top: 0;
            left: 0% !important;
            width: 100%;
            height: calc(100% - 2rem);
            padding: 0 .5rem;
        }
    }
}

.lobby__professionals--label {
    display: none;
}

.lobby__professionals--avatar {
    display: block;
    box-shadow: 0 5px 8px 0px rgba(0, 0, 0, 0.6);
    height: calc(100% - 2rem) !important;
}

.lobby__professional--avatar-container {
    .lobby__professionals--place {
        height: 2rem;

        &:before {
            font-size: 1em;
            text-shadow: 0 2px 4px #000000;
        }
    }
    &:first-child {
        .lobby__professionals--place:before {
            content: "|";
        }
    }
    &:nth-child(2) {
        .lobby__professionals--avatar-frame {
            top: 5% !important;
        }
        .lobby__professionals--place:before {
            content: "||";
        }
    }
    &:nth-child(3) {
        .lobby__professionals--avatar-frame {
            top: 5% !important;
        }
        .lobby__professionals--place:before {
            content: "|||";
        }
    }
}

.lobby__professionals--place {
    display: block;
    font-size: 1em;
    color: $color__white;
}

.lobby__professional--wrapper {
    &.default{
      @media only screen and (min-height: $break-point--900 ) {
        @include glory__container($large);
      }
      @media only screen and (min-width: $break-point--480 ) {
        @include glory__container($large);
      }
      @media only screen and (max-height: $break-point--900 ) and (orientation: landscape) {
        @include glory__container($medium);
      }
      @media only screen and (max-height: $break-point--768 ) and (orientation: landscape) {
        @include glory__container($small);
      }
      @media only screen and (max-width: $break-point--1024 ) and (orientation: portrait) {
        @include glory__container($medium);
      }
      @media only screen and (max-width: $break-point--480 ) and (orientation: portrait) {
        @include glory__container($medium);
      }
      @media only screen and (max-width: $break-point--320 ) and (orientation: portrait) {
        @include glory__container($small);
      }
      @media only screen and (max-height: $break-point--768 ) and (orientation: portrait) {
        @include glory__container($medium);
      }
      @media only screen and (max-height: $break-point--680 ) and (orientation: portrait) {
        @include glory__container($small);
        margin: .3rem auto 0;
      }
    }
    &.large {
      @include glory__container($large);
    }
    &.medium {
      @include glory__container($medium);
      &:not(.leaderboards) {
        @media only screen and (max-width: $break-point--440) {
          @include glory__container($small);
          margin: .3rem auto 0;
        }
      }
    }
    &.small {
      @include glory__container($small);
      margin: .3rem auto 0;
    }
    &.leaderboards {
      @media only screen and (min-width: $break-point--1280) {
        @include glory__container($large);
      }
      @media only screen and (max-width: $break-point--480) {
        @include glory__container($small);
      }
    }
  }
