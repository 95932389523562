.challenges-dropdown__overlay {
    background-color: $popup--backdrop;
}

.challenges-dropdown__refresh-time,
.challenges-dropdown__more {
    color: #d3d7ff;
}

.challenges-dropdown__top-tabs__scroll {
    background-color: #00346f;
    visibility: visible !important;
}

.challenges-dropdown__top-tabs__scrollbar,
.weekly-challenges-dropdown__top-tabs__scrollbar {
    background-color: $color__chips;
    visibility: visible !important;
}

.challenges-dropdown__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    margin: 0;
    transition: none !important;

    >p {
        text-decoration: none;
    }

    &.daily-challenges-dropdown__top-tabs__bclass__selected {
        background-color: transparent !important;

        >p {
            color: $color__chips !important;
        }
    }

    &.weekly-challenges-dropdown__top-tabs__bclass__selected {
        background-color: transparent !important;

        >p {
            color: $color__chips !important;
        }
    }
}

.challenges-dropdown__weekly-challenges {
    background-image: url(imageUrl("backgrounds/bg-second.png")), linear-gradient(to bottom, #8435cb, #8335ca, #8134c8, #8034c7, #7f33c6);
    background-repeat: repeat;
    box-shadow: inset 0px -8rem 8rem 0px #f575fa !important;
    border-top: 1px solid #f575fa;
    background-size: 70%;
}

.challenges-dropdown__container--weekly-challenge {
    border: none;
}

.challenges-dropdown__container--weekly-challenge .pib__wrapper {
    background-image: linear-gradient(to bottom, #a061ff, #8b4ce7, #7536cf, #601fb7, #4a00a0);
    border-top: 1px solid #f6d1ff !important;
    box-shadow: 0 2px 5px 0px #011034, inset 0px 0px 9px 2px #bf71fa;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #ff93ff;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container.progress-classic :not(.overrided):nth-last-child(2) {
    background-color: #001a4c !important;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    box-shadow: inset 0px 1px 2px 0px #ffff00;
}

.challenges-dropdown__container--weekly-challenge .pib__container--title {
    color: #f5a724;
}

.challenges-dropdown__container--weekly-challenge .pib__text--progress-bar {
    color: #bf9aff;
}

.challenge-box--progress-info-box--skippies-button {
    border-radius: 1rem;
    background-image: linear-gradient(to bottom, #6b00da, #5d01ca, #5001b9, #4301a9, #360099);
    box-shadow: inset 0px -2px 2px 0px #00002a, inset 0px 2px 1px 0px #d100fd;
}