.gifts-tab__container--premium-gifts {
    border: none;
}

.gifts-tab__text--premium-gifts {
    color: #a6aeff;
    font-size: 1.6rem;
    font-weight: $font-weight-semibold;
}

.gift-container__container--gift {
    border-radius: $container-border--radius;
    .gift-container__wrapper--gift-item {
        border-radius: $container-border--radius;
        overflow: hidden;
        background-color: #1339b3;
        border-top: 1px solid #4597eb;
        box-shadow: 0.2rem 0.2rem 0 0.1rem rgb(0 0 0 / 30%);
        &.in-game {
            .image-container {
                background-color: transparent!important;
                border-top: none !important;
            }
        }
    }
}

.image-container {
    @include aspect-ratio(1,1);
    margin: 1rem 1.5rem !important;
}

.gift-container__wrapper--gift-item__premium {
    background-color: #591ab7!important;
    border-top: 0.2rem solid #b234ff!important;
    box-shadow: 0.2rem 0.2rem 0 0.1rem rgb(0 0 0 / 30%);
    background-size: cover;
    .image-container {
        background-color: transparent !important;
        border-top: none !important;
    }
}

.gift-container__container--gift {
    width: 100%;
    margin: 0 !important;
}
