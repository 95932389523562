$background-color: #2261a8;
$background-inner-shadow: #2261a8;
$container-bonus-upper-border-color: #2e83c4;

.mini-game-double-up__container {
    border: none !important;
    background: none;
    @include popupContainer($container-bonus-upper-border-color,
    $background-color, $background-inner-shadow);
}

.mini-game-double-up__won--text {
    text-shadow: 1px 3px 1px #1c497c;
}

.mini-game-double-up__image {
    height: 10rem !important;
}

.mini-game-double-up__reward__amount {
    font-weight: $font-weight-black;
    font-size: $text__size--x-large;
    text-shadow: 1px 3px 1px #1c497c;
    font-style: italic;
}