.pib__wrapper {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-box-shadow-color,
        $container-dark-bottom-border-color);
}

.pib__container--title {
    color: #2e89ed;
}

.pib__svg-icon-cmp {
    color: $accent--1;
}

.pib__text--progress-bar {
    color: #2e89ed;
    font-style: italic;
}