

.edit-profile__custom-select__option {
    background-color: transparent;
    > p {
        color: $popup-font--color;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.edit-profile__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-font--color;
            font-size: $text__size--normal;
            text-decoration: none;
        }
    }
    &:hover {
        background-color: #edf0f1;
        > p {
            color: #194098;
        }
    }
}

.edit-profile__selected-option {
    color: $color--white;
}

.edit-profile__custom-select__extend--button {
    color: $color--white !important;
}

.edit-profile__container--main-group {
    &.avatar {
        flex: 100% !important;
        padding: 0 !important;
    }
}

.edit-profile__custom-select__element {
    background-color: rgba(0,0,0,.3);
    border-bottom: 1px solid #4e8dd4;
    border-radius: 10rem !important;
}

.edit-profile__container--name-change {
    top: 1rem !important;
}

.edit-profile__custom-select {
    height: 4rem;
    @media only screen and (max-width: $break-point--768) {
        height: 4rem;
    }
}
