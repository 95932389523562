.search-bar__container--history {
    width: calc(100% - 9.5rem) !important;
    border: none;
    background-color: #ffffff;
    &.opened {
        opacity: .9;
    }
    
    > p {
        color: gray !important;
        &:hover {
            background-color: #edf0f1;
            color: $popup-accent-1 !important;;
        }
    }
}

.search-bar__delete {
    color: gray !important; 

    &:hover {
        color: $popup-accent-1 !important;;
    }
}