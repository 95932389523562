$input-desktop-height: 26rem;
$input-mobile-height: 20rem;
$input-closed-height-desktop: 7rem;
$input-closed-height-mobile: 4rem;
$maintenance-element-height: 5rem;
$chat-with-height: 2rem;
$chat-with-margin-top: 0.25rem;
$chat-with-el-height: $chat-with-height + $chat-with-margin-top;

.pf-tab__container--conversations {
    background-image: none;
    background-color: $chat__bg-color;
}

.pf-tab__search-bar__input {
    background-color: $color--white;
    color: #969696 !important;
}

.pf-tab__username {
    height: 3.5rem !important;
    padding: .5rem 1rem;
    font-size: 1.2rem;
    background-color: #4b4b4bd1;
    color: #ffffff;
    border-radius: 10rem;
    width: unset !important;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
}

.pf-tab__container--messages {
    padding-top: 3rem !important;
}

.pf-tab__container--messages {
    @include touch-scroll();
    width: 100%;
    height: calc(100% - #{$input-closed-height-desktop + $chat-with-el-height}) !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;

    @media screen and (max-width: $break-point--768) {
        height: calc(100% - #{$input-closed-height-mobile + $chat-with-el-height}) !important;
    }
}