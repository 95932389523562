body {
  font-family: $skin-font-family;
  background: $background__pattern;
  background-size: contain;
  background-position: center center;
    @media screen and (max-width: $break-point--768){
        background-size: cover;
    }
}

button {
  >p {
    font-family: $skin-font-family;
  }
}

input {
  font-family: $skin-font-family;
}

textarea {
  font-family: $skin-font-family;
}

::-webkit-input-placeholder {
  color: #999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
}

.element-disabled {
  opacity: .5;
}

.element-disabled:hover {
  cursor: pointer !important;
}
