.stamp-card__top-container {
    background-image: url(imageUrl('shop/stamp-card/TOP.png'));
}

.stamp-card__bottom-container {
    background-image: url(imageUrl('shop/stamp-card/BOTTOM.png'));
    background-size: cover;
    background-position: top;
}

.stamp-card__container__wrapper {
    width: calc(100% - 10rem);
    left: 5rem;
    @media screen and (max-width: $break-point--680) {
        left: 5rem;
        width: calc(100% - 10rem);
    }
    @media screen and (max-width: $break-point--568) {
        left: 2rem;
        width: calc(100% - 4rem);
    }
    @media screen and (max-width: $break-point--440) {
        left: 1rem;
        width: calc(100% - 2rem);
    }
}

.stamp-card__middle-container {
    background: url(imageUrl('shop/stamp-card/LINE.png')) #e4e5e9;
}
