$button-border-radius: 10rem;

.ranked-tile-component {
    border-radius: $container-border--radius;
    background: linear-gradient(90deg, #410a8d, #5731b3 50%, #410a8d);
    border-top: 2px solid #8f19c0;
}

.ranked-room-tile__entry--currency-class {
    text-shadow: 0 1px 0 #2b0071, 0 3px 0 #8775f7, 0 5px 5px #000;
}

.ranked-room-tile__play-bclass {
    border-radius: $button-border-radius;
}

.ranked-room-tile__play-btn--wrapper {
    background-color: #00000021;
    box-shadow: inset 0px 2px 2px 0px $black__transparent--06,
    0px 1px 0 #9574d3;
    border-radius: $button-border-radius;
}

.ranked-room-tile__inner-glow {
    border-left: 1px solid #470a9e;
    border-right: 1px solid #470a9e;
    box-shadow: inset 0 -1px 25px -5px #5f12cb;
}