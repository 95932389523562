.cash-back__container {
    height: 100%;
    overflow-y: unset !important;
    @include scrollbar();
    background-image: none !important;
}

.cash-back__title {
    position: relative;
    text-align: center;
    font-size: 5vh;
    text-transform: uppercase;
    padding: 0 1rem;
    font-weight: $font-weight-extra-bold;
    text-shadow: 0 2px 0 #9c2bff, 0 4px 0 #270000, 0 7px 13px #000;
}