.list-users-box__container--list-title {
    &:before {
        display: none !important;
    }
    margin: 0 !important;
}

.list-users-box__container--delete-button {
    color: $color__white;
}

.list-users-box__container--action-button {
    color: $color__white;
}

.list-users-box__container--no-users {
    height: auto !important;
    padding: 3rem;
    margin-top: 0 !important;
}

.list-users-box__container--list-friends-users {
    margin-top: 1rem;
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-box-shadow-color,
        $container-dark-bottom-border-color);
        .list-users-box__container--no-users {
            font-size: 1.6rem;
            font-weight: $font-weight-semibold;
            color: #9fcaf0;
        }
}


list-users-box {
    width: 100% !important;
}

.list-users-box__container--info {
    .username {
        >p {
            font-weight: $font-weight-semibold;
        }
    }
}
