.leaderboards__tabs--scroll {
    background-color: #7d46ae;
}

.leaderboards__tabs--scrollbar {
    background-color: #ddc1f8 !important;
}

.leaderboard__top-tabs__selected {
    color: #ddc1f8 !important;
}

.leaderboard__top-tab__button {
    width: 10rem;
    @media screen and (max-width: $break-point--768) {
      height: 3.5rem !important;
    }
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}

.leaderboard__top-tab__button-bclass {
    background-color: transparent !important;
    margin: 0;
    width: 100%;
    transition: none !important;
    color: #7d46ae;
    &:hover {
        color: #7d46ae;
    }
    &.leaderboard__top-tabs__selected {
        background-color: transparent !important;
    }
}

.leaderboard__container {
    @include scrollbar(0.2rem, 0.2rem, #8a48c4);
}