.item-reward__reward {
    @include aspect-ratio(0.725, 1);
    width: auto !important;
    height: auto !important;
    border: none;
    border-radius: 10%;

    &.backgroundImage {
        box-shadow: 0 6px 10px 0 rgb(68 25 78 / 60%);
        background-color: transparent;
        background-image: var(--item-reward-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &.current {
        box-shadow: 0 0 18px 3px #ec00ff;
        border: none !important;
    }

    &.locked {
        filter: brightness(0.6);
    }

    &.claimed {
        background-image: var(--item-reward-bg);
        background-color: #062b33;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: #160b1a;
            opacity: 0.8;
        }
    }
}

.item-reward__reward--booster {
    font-weight: $font-weight-bold;
    text-align: center;
}