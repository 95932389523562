.avatars-list-drawer__wrapper {
    @include scrol-container();
    @include touch-scroll();
    position: relative;
    width: calc(100% - 4rem);
    margin: 0 2rem !important;
    height: calc(100% - 6rem) !important;
    border-radius: .8rem;

    @media only screen and (max-width: $break-point--768) {
        width: calc(100% - 4rem);
        margin: 0 2rem;
        height: calc(100% - 6rem);
    }

    @media only screen and (max-width: $break-point--480) {
        width: calc(100% - 4rem);
        margin: 0 2rem;
        height: calc(100% - 6rem);
    }

    @media only screen and (max-height: $break-point--440) and (max-width: $break-point--768) {
        width: calc(100% - 4rem);
        margin: 0 2rem;
        height: calc(100% - 6rem);
    }
}

.avatars-list-drawer__list {
    height: auto;
}

.avatars-list-drawer__container--top-bar {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 5rem !important;
    z-index: 1;

    @media only screen and (max-width: $break-point--768) {
        height: 3rem !important;
    }

    .title-text {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        >p {
            font-size: $text__size--large;

            @media only screen and (max-width: $break-point--768) {
                font-size: $text__size--normal;
            }
        }
    }
}

.avatars-list-drawer__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.avatars-list-drawer__container--top-bar-title__new--design {
    height: 5rem;
    font-size: 1.8rem;
    font-weight: $font-weight-semibold;
    text-align: center;
    color: #ffffff61;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.avatars-list-drawer__container--top-bar-title__container {
    display: none;
}