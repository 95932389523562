.report__custom-select {
    background: $black__transparent--03;
    color: $white__transparent--05;
    cursor: pointer;
    border-radius: 10rem;
    height: 4rem;
    @media only screen and (max-width: $break-point--768) {
        height: 3rem;
    }
}

.report__custom-select__option {
    background-color: transparent;
    > p {
        color: $popup-font--color;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.report__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-font--color;
            font-size: $text__size--normal;
            text-decoration: none;
        }
    }
    &:hover {
        background-color: #edf0f1;
        > p {
            color: #194098;
        }
    }
}

.report__selected-option {
    color: $color--white;
}

.report__custom-select__extend--button {
    color: $color--white !important;
}

.report__container--middle-text {
    &:before {
        display: none;
    }
}
