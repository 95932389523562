.challenge-progress-info-box--wrapper-weekly {
    background-image: linear-gradient(to bottom, #a061ff, #8b4ce7, #7536cf, #601fb7, #4a00a0);
    border-top: 1px solid #f6d1ff !important;
    box-shadow: 0 2px 5px 0px #011034, inset 0px 0px 9px 2px #bf71fa;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .challenge-progress-info-box__progress-bar .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    box-shadow: inset 0px 1px 2px 0px #ffff00;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .challenge-progress-info-box__progress-bar .progress-bar__progress--container.progress-classic :not(.overrided):nth-last-child(2) {
    background-color: #001a4c !important;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .progress-bar__wrapper .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #ff93ff;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__text--progress-bar {
    color: #bf9aff;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__challenge-title {
    color: #f5a724;
}

.challenge-progress-info-box--wrapper-daily {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.challenge-progress-info-box--wrapper-daily .challenge-progress-info-box__challenge-title {
    color: #2e89ed;
}

.challenge-progress-info-box--wrapper-daily .challenge-progress-info-box__text--progress-bar {
    color: #2e89ed;
}