@include font-face("Montserrat", $font-weight-light, normal) {
    src: url(fontUrl("Montserrat/MontserratLight.woff")) format("woff"),
    url(fontUrl('Montserrat/MontserratLight.ttf')) format("truetype");
}

@include font-face("Montserrat", $font-weight-normal, normal) {
    src: url(fontUrl("Montserrat/MontserratRegular.woff")) format("woff"),
    url(fontUrl('Montserrat/MontserratRegular.ttf')) format("truetype");
}

@include font-face("Montserrat", $font-weight-bold, normal) {
    src: url(fontUrl("Montserrat/MontserratBold.woff")) format("woff"),
    url(fontUrl('Montserrat/MontserratBold.ttf')) format("truetype");
}

@include font-face("Montserrat", $font-weight-black, italic) {
    src: url(fontUrl("Montserrat/MontserratBlackItalic.woff")) format("woff");
}

@include font-face("Montserrat", $font-weight-black, normal) {
    src: url(fontUrl("Montserrat/MontserratBlack.woff")) format("woff");
}
