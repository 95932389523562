.custom-blitz-room__container {
    @include coloredContainer($container-dark-background-color,
    $container-dark-upper-border-color,
    $container-dark-inner-shadow-color,
    $color__black);
    padding: 1rem;
}

.custom-blitz-room__tournament--counter {
    display: flex;
    align-items: center;
    box-shadow: 0 2px 1px #05122b, 0 -2px 1px #093848;
    font-weight: $font-weight-semibold;
}

.custom-blitz-room__tournament--counter__icon {
    display: block !important;
    margin-right: 0.5rem;
}

.custom-blitz-room__place {
    width: 1rem;
    font-weight: $font-weight-extra-bold;
    text-align: center;
    &.first {
        color: $color__chips;
    }

    &.second {
        color: #9cb4b8;
    }
}

.custom-blitz-room__transaction--container {
    position: relative;
}

.custom-blitz-room__transaction--separator {
    display: block !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: .1rem;
    height: 80%;
    background-color: #2e89ed;
}

.custom-blitz-room__tournament--creator {
    > span {
        color: #2e89ed;
    }
}

.custom-blitz-room__tournament--expiry--date {
    > span {
        color: #2e89ed;
    }
}

.custom-blitz-room__entry--fee__text {
    font-weight: $font-weight-semibold;
    color: #2e89ed;
}

.custom-blitz-room__tournament--club__name {
    > span {
        color: #2e89ed;
    }
}