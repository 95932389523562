.matching__wrapper {
    background-color: $popup--backdrop;
}

.matching__container {
    box-shadow: 0 0.5rem 1.2rem 0.1rem #000000;
    border: none !important;

    &.tier0-5 {
        background: linear-gradient(#a5998b, #ded4ce);
        border-top: none !important;
        box-shadow: inset 0 3px 3px #e2d5c7, inset -3px 0 2px #786d67, inset 3px 0 2px #62574e, inset 0 -3px 2px #62574e;
    }

     &.tier1 {
        background: linear-gradient(#ecedef, #83bcda);
        border-top: none !important;
        box-shadow: inset 0 3px 3px #ffffff, inset -3px 0 2px rgb(0 55 99 / 60%), inset 3px 0 2px rgb(0 55 99 / 60%), inset 0 -3px 2px rgb(15 53 74 / 84%);
    }

     &.tier2{
        background: linear-gradient(#21edd1, #21ed97);
        border-top: none !important;
        box-shadow: inset 0 3px 3px rgb(84, 255, 255), inset -3px 0 2px rgb(11 85 55 / 94%), inset 3px 0 2px rgb(11 85 55 / 94%), inset 0 -3px 2px rgb(0, 133, 47);
    }

    &.tier3 {
        background: linear-gradient(#21a5de, #2149de);
        border-top: none !important;
        box-shadow: inset 0 3px 3px #5ecbff, inset -3px 0 2px #001779, inset 3px 0 2px #001779, inset 0 -3px 2px #000078;
    }

    &.tier4 {
        background: linear-gradient(#9421ef, #7221ef);
        border-top: none !important;
        box-shadow: inset 0 3px 3px #cc5aff, inset -3px 0 2px #1f0384, inset 3px 0 2px #1f0384, inset 0 -3px 2px #070084;
    }

    &.tier5 {
        background: linear-gradient(#e7b75e, #e6983b);
        border-top: none !important;
        box-shadow: inset 0 3px 3px #fffaa0, inset -3px 0 2px #844600, inset 3px 0 2px #844600, inset 0 -3px 2px #7d3000;
    }

    &.ranked {
        background: linear-gradient(90deg,#410a8d,#5731b3 50%,#410a8d);
        border-top: none !important;
        box-shadow:  inset 0 3px 3px #a14bdf, inset -3px 0 2px #49178f, inset 3px 0 2px #49178f, inset 0 -3px 2px #200546;
    }
}

.matchmaking__title-bar {
    display: none;
}

.matchmaking-popup__button__cancel {
    width: 28rem !important;
    margin: 0 auto 3.5rem !important;
    height: 4rem;
    max-width: 90%;
}

.matchmaking__title {
    display: flex !important;
    width: 100%;
    justify-content: center;
    @extend %text;
    margin: 2rem 0;
    font-size: 2.2rem;
    font-weight: $font-weight-bold;

    &.tier0-5 {
        text-shadow: 0 1px 2px #5e5149;
        color: #362b26;
    }

     &.tier1 {
        text-shadow: 0 1px 2px #1f3a4f;
        color: #284c66;
    }

     &.tier2{
        text-shadow: 0 1px 2px #0c382e;
        color: #0e3d30;

    }

    &.tier3 {
        text-shadow: 0 1px 2px #0d3075;
        color: #d9f3ff;
    }

    &.tier5 {
        text-shadow: 0 1px 2px #502a05;
        color: #4c260a;
    }
}
