.shop-gems__wrapper {
    @media only screen and (max-width: $break-point--380) {
        max-width: initial !important;
    }
}

.shop-gems__box {
    display: none !important;
}

.shop-gems__container--items {
    max-width: 45rem !important;
}