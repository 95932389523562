.creator-code__container {
    background-image: url(imageUrl("shop/affiliate-code_background.png"));
    @include coloredContainer($container-dark-background-color,
    $container-dark-upper-border-color,
    $container-dark-box-shadow-color,
    $container-dark-bottom-border-color);
}

.creator-code__info {
    color: #67a2ea;
}

.creator-code__input {
    padding: 0 !important;
}

.creator-code__container--form__button__timer {
    color: #531a0c !important;
}