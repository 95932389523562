.room__wrapper {
    border-radius: $border--radius;

    &.custom {
        box-shadow: inset 0 1px 0 0 #404689,
        inset 0 0 30px 10px #2f3466;
        background: url(imageUrl("lobby/customRoom.png")) no-repeat center bottom, linear-gradient(#4555ab, #8822ed);
        box-shadow: inset 0 3px 2px #859af0, inset 0 -3px 2px #2b008e, inset 3px 0 2px #0c005f, inset -3px 0 2px #0c005f;
        background-size: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .box__container--room-icon {
            display: none !important;
        }

        .nav--room--box__wrapper--texts {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: $font-weight-extra-bold;
            font-size: 5rem !important;
            color: #e6e6e6;
            text-shadow: 1px 1px 2px #171175;
            margin-left: 0 !important;
            margin-top: 0 !important;

            > span {
                text-transform: lowercase;
                font-style: italic;
            }

            @media screen and (max-width: $break-point--680) {
                font-size: 4rem !important;
            }

            @media screen and (max-width: $break-point--480) {
                font-size: 3rem !important;
            }

            @media screen and (max-width: $break-point--380) {
                font-size: 2.5rem !important;
            }
        }

        .nav--room--box__wrapper--info {
            font-weight: $font-weight-bold;
            display: flex !important;
            justify-content: center;
            font-size: $text__size--large;
        }

        .tile__text--name {
            line-height: initial !important;
        }
    }
}

.nav--box__image--wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    height: 200% !important;
    @media screen and (max-width: $break-point--768) {
        height: 100% !important;
    }
    &.custom {
        max-height: initial;
        max-width: initial;
        top: 50% !important;
        left: 50% !important;
        width: 200% !important;
        position: absolute !important;
        display: block;
    }
}