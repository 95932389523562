$chat__nav-bar-container--bg-color: #efefef;


.chats-nav-bar__container {
    background-color: $chat__nav-bar-container--bg-color !important;
    height: auto !important;
}

.chats__component-container {
    @media only screen and (max-width: $break-point--768) {
        box-shadow: none;
    }
}

.chats-nav-bar__container--button-close {
    width: 2rem !important;
    height: 2rem !important;
}

.chats-nav-bar__container--button-close--bclass {
    font-size: $text__size--x-large;
    color: $popup-accent-1;
}

.chats-nav-bar__container--arrow-back {
    width: 100% !important;
}

.chats-nav-bar__button--back--bclass {
    color: $popup-accent-1 !important;
    font-size: 1.8rem !important;
}

.chats-nav-bar__tab--container {
    align-items: flex-end !important;
    margin-bottom: .1rem;
}

.chat__top-tabs__bclass {
    width: 10rem !important;
    margin: 0;
    color: #989aba !important;
    background-color: transparent !important;
    transition: none !important;
    @media screen and (max-width: $break-point--380) {
        width: 8rem !important;
    }
    > p {
        color: #989aba !important;
    }
    &.chat__top-tabs__selected {
        color: $popup-accent-1 !important;
        background-color: transparent !important;
        > p {
            color: $popup-accent-1 !important;
        }
    }
}


.chat__top-tabs__scroll {
    background-color: #989aba !important;
}

.chat__top-tabs__scrollbar {
    background-color: $popup-accent-1 !important;
}

.top-tabs__list-item--navigation__notification {
    @media only screen and (max-width: $break-point--320) {
        left: unset !important;
    }
}

.chat__top-tabs__scrollable--container {
    height: 100% !important;
    display: block !important;
}

.chat__top-tabs__tab-separator {
    background-color: #cdd8dc;
}.top-tabs__list-item--navigation__notification {
    min-width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1rem !important;
    top: 10% !important;
    &.clubChat {
        right: 20% !important;
        @media only screen and (max-width: $break-point--768) {
            right: 25% !important;
        }
    }

    &.globalChat {
        right: 10% !important;
        @media only screen and (max-width: $break-point--768) {
            right: 15% !important;
        }
    }

    &.privateChat {
        right: 7% !important;
        @media only screen and (max-width: $break-point--768) {
            right: 10% !important;
        }
    }
}

.chat__chat-container {
    height: calc(100% - 7rem) !important;

    @media screen and (max-width: $break-point--768) {
        height: calc(100% - 7rem) !important;
    }
}

.chat__top-tabs__tab {
    padding: 0 !important;
    margin-top: .7rem;
}

.chats-nav-bar__container--nav {
    height: 3rem;
    width: 3rem;
    @media only screen and (max-width: $break-point--440) {
        width: 3rem;
    }
}