.ranked-games-leaderboard-stats {
    background: #521dab;
}

.ranked-games-leaderboard__title {
    color: #d6daff;
    font-weight: $font-weight-bold;
    text-shadow: 0 -1px 0 #fafeff, 0 1px 0px #8775f7, 0 2px 0px #000;
}

.ranked-games-leaderboard-stats__info-wrapper {
    background: linear-gradient(90deg,#3a1187,#320f74 20%,#320f74 80%,#3a1187);
}
