.cgr__wrapper {
    padding: .5rem;
    &.expanded {
        background-color: $container-dark-background-color !important;
    }
    background: linear-gradient(#1d3bb2,#001d5e);
    border-top: 1px solid #5a8df7;
    box-shadow: 0 6px 10px 0 #15062ebf;
    border: none !important;
    &.cgr__wrapper__allIn {
        background: linear-gradient(#8422f3,#441286);
        border-top: 1px solid #d07dff;
        border: none !important;
    }
    // @include coloredContainer($container-dark-background-color,
    //     $container-dark-upper-border-color,
    //     $container-dark-inner-shadow-color,
    //     $container-dark-bottom-border-color);
}

.cgr__container--countdown {
    border-radius: $border--radius;
}

.cgr__wrapper--header {
    background-color: transparent !important;
}