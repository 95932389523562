.notifications-dropdown__overlay {
    background-color: $popup--backdrop;
}

.notifications-dropdown__container--notification {
    background-color: transparent;
    &.highlighted {
        background-color: transparent;
    }
    &:not(.highlighted) {
        background-color: transparent;
    }
}
