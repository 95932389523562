.friends-popup__search--bar--input {
  background-color: rgba(0,0,0,.3) !important;
  box-shadow: none !important;
}

.friends-list__close--friend-button {
    color: $color__white;
}

.friends__list--header--title-left {
  color: #000c6d;
  font-weight: $font-weight-semibold;
  width: 100% !important;
  @media only screen and (max-width: $break-point--380) {
      width: 100%;
  }
}

.friends__container--search-outer {
  height: 4rem !important;
  min-height: 4rem !important;
  padding: 0 6.5rem !important;
  @media only screen and (max-width: $break-point--1366) {
      padding: 0 6rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
      padding: 0 1rem !important;
  }
}

.friends__container--search {
  height: 100% !important;
}

.friends__container--friends-users__list {
  width: 100% !important;
  margin: 2rem 0 !important;
  min-height: 10% !important;
  padding: 0 6.5rem !important;
  @media only screen and (max-width: $break-point--1366) {
      padding: 0 6rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
      padding: 0 1rem !important;
  }
}