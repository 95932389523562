.personal-notes--comments {
    background-color: #001d5e;
    color: white;
    border-top: 1px solid #0040e2;
    box-shadow: 0 4px 4px 0 #00226f;
}

.personal-notes__simple-message-input {
    background-color: #2261a8;
}

