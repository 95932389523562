.blocked__search--bar--input {
  box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
  background: rgba(0,0,0,.3);
  border: none;
  border-bottom: 1px solid #4e8dd4;
  &:active, &:focus {
    box-shadow: inset 0 1px 0 0 #28293b, inset 0 -2px 3px -1px #2f3269;
    background: rgba(0,0,0,.3);
    border: none;
    border-bottom: 1px solid #4e8dd4;
  }
}
.blocked__container--blocked-users__list {
  width: 100% !important;
  margin: 2rem 0 !important;
  min-height: 10% !important;
  padding: 0 6.5rem !important;
  @media only screen and (max-width: $break-point--1366) {
    padding: 0 6rem !important;
  }
  @media only screen and (max-width: $break-point--768) {
    padding: 0 1rem !important;
  }
}