.ranked-stats-rating-container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
}

.ranked-stats-rating-container__rating--label-cclas {
    background: #1c2266 !important;
    box-shadow: inset 0 1px 0 #003fe1, 0 1px 7px 1px $black__transparent--05;
}