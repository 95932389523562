// Main

$main: #192540 !default;
$secondary: #4f0741 !default;
$dark-secondary: #3d0738 !default;
$light-secondary: #501A5F !default;

// Top Bar
$color--bar: #131e35 !default;

// Accents
$accent--1: #f3a522 !default;
$accent--2: #ce8000 !default;

// Highlight
$active--color: #00b93e !default;
$color--negative: #ff084c !default;
$color--selected: #f6b229 !default;
$highlightColor: #BF5C00 !default;

// Currency
$color--chips: #ffa000 !default;
$color--gems:  #ffffff !default;

// Popup
$promo__popup--bg: #03665e !default;
$promo__popup--tabs: #053d39 !default;

// Social
$google: #DD4B39;
$facebook: #3B5999;

// Toaster colors
$color--info: #005be9;
$color--warn: #ffa000;
$color--error: #ff084c;
$color--success: rgba(100, 155, 30, 1);

// Chat
$color__link: #1143bd !default;
$color__admin: $color--info !default;

// Solid colors
$color__black: #111111;
$color__white: #ffffff;
$color__disabled: #607D8B;
$color__disabled--hover: #455A64;

// Shady colors
$black__transparent--02: rgba(0, 0, 0, .2);
$black__transparent--03: rgba(0, 0, 0, .3);
$black__transparent--05: rgba(0, 0, 0, .5);
$black__transparent--06: rgba(0, 0, 0, .6);
$black__transparent--07: rgba(0, 0, 0, .7);
$black__transparent--08: rgba(0, 0, 0, .8);
$black__transparent--09: rgba(0, 0, 0, .9);
$black--3: #333333;
$black--7: #9B9B9B;
$black--b: #CFCECD;
//$black--b: #d7d6d5;


$white__transparent--03: rgba(255, 255, 255, .3);
$white__transparent--05: rgba(255, 255, 255, .5);
$white__transparent--07: rgba(255, 255, 255, .7);
$white__transparent--08: rgba(255, 255, 255, .8);
$white__transparent--09: rgba(255, 255, 255, .9);

//popup

$popup-accent-1: #194098;
$light-accent-2: #00a8cc;
$popup-font--color: #0c0f3e;
$popup-light-gray: #d5e5dd;

$popup--backdrop: rgba(3,3,17,.9);