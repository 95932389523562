.add__button-host {
    background-color: #1111112e;
}

.add-button_button {
    > .add-button__icon {
        color: $color__white !important;
    }
    transition: none !important;
    &:hover {
        border: none !important;
        > .add-button__icon {
            color: $color--chips !important;
        }    
    }
}