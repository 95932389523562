$icon-accent: #2b6775;

.fis__text--option {
    font-weight: $font-weight-bold;
}

.fis__text--value {
    font-size: $text__size--large !important;
}

.fis__text--value{
    &.bet,
    &.chips {
      @include chip-sign($text__size--large);
    }

    &.gems {
      @include chip-sign( 2rem, $icon-gems, $color__white );
    }
}

.fis__container--values {
  width: 25rem !important;
  background-color: rgba(0,0,0,.3);
  border-bottom: 1px solid #4e8dd4;
  border-radius: 10rem !important;
  justify-content: space-between !important;
  padding: 0 !important;
}

.fis__container--slide {
    width: 14rem !important;
}

.fis__container--option {
  &.image {
    width: 14rem !important;
  }
}

.fis__text--option {
  width: 14rem !important;
  min-width: 14rem !important;
}