.game-invite__container {
    border: none !important;
    @include popupContainer(
        $container-bonus-upper-border-color,
        $background-inner-shadow,
        $background-color
    );
}

.game-invite__room-info-section--transactions {
    width: 100%;
}

.game-invite__player-section {
    padding-left: .5rem !important;
}