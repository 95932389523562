.custom-blitz-seat--wrapper {
    border-radius: .8rem;
    box-shadow: 0 1px 0 #2c7bd1, inset 0 1px 0 #0d2c4e;
    &.isInFinalTable {
        box-shadow: 0 1px 0 #ffbf39, inset 0 1px 0 #7e7400;
    }
}

.custom-blitz-seat--avatar {
    border-radius: .8rem;
    overflow: hidden;
}

.custom-blitz-seat__invite--icon {
    color: $color__white !important;
    transition: none !important;
    &:hover {
        color: $color__chips !important;
    }
}

.custom-blitz-seat__add--icon {
    color: $color__white !important;
    transition: none !important;
    &:hover {
        color: $color__chips !important;
        border: none !important;
    }
}

.custom-blitz-seat--locked__with--reason__icon {
    color: $color__white !important;
    transition: none !important;
    font-size: 5rem;
    &:hover {
        color: $color__chips !important;
    }
}

.custom-blitz-seat--locked__with--reason {
    border: none !important;
}

.custom-blitz-seat__invite--button {
    @media screen and (max-width: $break-point--1280) {
        font-size: 4rem !important;
    }
    @media screen and (max-width: $break-point--1024) {
        font-size: 3rem !important;
    }
    @media screen and (max-width: $break-point--900) {
        font-size: 1em !important;
    }
    @media screen and (min-height: $break-point--1024) and (max-width: $break-point--900) {
        font-size: 2.5rem !important;
    }
    @media screen and (max-width: $break-point--360) {
        font-size: 4rem !important;
    }
}

.custom-blitz-seat__losers--overlay {
    border-radius: 0.8rem;
}