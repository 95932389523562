.progressive-ads-bonus__next-bonus {
    color: #a6aeff;
    font-weight: $font-weight-bold;
    padding: 3em 0;
}

.progressive-ads-bonus__reward-cmp {
    &.current {
        transform: scale(1.05);
    }
}
