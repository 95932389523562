$color-timer-icon: #48d6ff;

.sic-host-class {
    border: none;
}

.sic__container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.sic__icon {
    @include icon($rent-timer, 2.6rem, $color-timer-icon);
    top: 1rem !important;
    right: 1rem !important;
}

.sic__image-container {
    margin: 3rem 1rem .5rem !important;
}

.sic__container__flag {
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}

.sic__giftable-item--count {
    background: linear-gradient(#ffed00, #ff9b00);

    &.none {
        color: #fff;
        background: linear-gradient(0deg, #6d0019, #a90329);
        border-bottom: 1px solid #111;
        box-shadow: inset 0 1px 0 0 #ff6f95, inset 0 1px 7px 1px transparent;
    }
}