.wins-ratio-box__wrapper {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $container-dark-bottom-border-color);
        margin-bottom: 1rem;
}

.wins-ratio-box__container--tournament-wins {
    background-color: $black__transparent--03;
    border-radius: 0.8rem;
    border: none;
    .wins-ratio-box__container--tournament-wins-title {
        background-color: transparent !important;
    }

    .wins-ratio-box__container--tournament-wins-info-box-inner {
        background-color: transparent !important;
    }
}

.wins-ration-box__button--reset {
    color: $color__white;
}
