.chips-item--img-ontainer {
    position: relative;
    > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: unset !important;
        max-height: unset !important;
        &.last-tier {
            width: 130%;
            transform: translate(-55%, -50%);
            max-width: 15rem;
    
            @media screen and (max-width: $break-point--360) {
                width: 115%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
