.product-flag__with--label {
    font-weight: $font-weight-extra-bold;
    text-align: center;
}

.shop-item-product-flag_daily-deal {
    margin-right: 4.5rem;
    > span {
        display: flex;
        align-items: center;
        height: 100%;
        width: 5rem;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(0deg, #8a0019  0%, #cf053b  100%) !important;
        justify-content: center;
    }
}

.itemProduct_flag__with--flag {
    background: linear-gradient(0deg, $container-ribbon-background-color-second 0%, $container-ribbon-background-color 100%);
    box-shadow: inset 0 1px 0 0 $container-ribbon-upper-border-color,
    inset 0 1px 7px 1px transparent;
    border: none;
    border-bottom: solid 1px $color__black;
    text-shadow: (0 0 0 $ribbon-text-shadow-color, 0.5px 0 0 $ribbon-text-shadow-color, 0 1px 0 $ribbon-text-shadow-color, 0.5px 1px 0 $ribbon-text-shadow-color);
    position: relative;
    overflow: hidden;
    padding: .5rem 1rem;
    border-radius: 2rem;
    display: table;
    text-transform: uppercase;
    font-size: 1.5rem !important;
    height: auto;
    width: auto;
    > p {
        width: auto;
        padding: 0;
        font-size: 1.5rem;
        font-weight: $font-weight-extra-bold;
        > span {
            box-shadow: inset 0 1px 0 0 $container-ribbon-upper-border-color,
            inset 0 1px 7px 1px transparent;
            border: none;
            border-bottom: solid 1px $color__black;
        }
    }
}

.product-flag__with--label {
    background: linear-gradient(0deg, $container-ribbon-background-color-second 0%, $container-ribbon-background-color 100%);
    box-shadow: inset 0 1px 0 0 $container-ribbon-upper-border-color,
    inset 0 1px 7px 1px transparent;
    border: none;
    > p {
        > span {
            box-shadow: inset 0 1px 0 0 $container-ribbon-upper-border-color,
            inset 0 1px 7px 1px transparent;
            border: none;
        }
    }
}