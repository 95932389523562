.promo-tile__buy-button-bclass {
    box-shadow: 0 10px 15px #212122;
    border-radius: 2rem;
    padding: 1rem;
    > p {
        text-shadow: 0 1px 1px #ff8f1e !important;
        color: #531a0c !important;
        font-size: 1.5rem !important;
    }
}


.promo-tile__star-class {
    &:after {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background: initial;
        background-color: white;
    }
}

.promo-tile__blink {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    height: 80%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.promo-tile__wrapper {
    border-radius: $border--radius;
    background-color: transparent;
    background: url(imageUrl("promo-tile/promo-default.jpg"));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 3px 2px rgb(255 255 255 / 35%), inset 0 -3px 2px rgba(15, 15, 15, 0.85), inset 3px 0 2px #11090052, inset -3px 0 2px #11090052;
    border-radius: 1.5rem;
}

.promo-tile__buy-button {
    min-width: auto !important;
    height: auto !important;
}
   