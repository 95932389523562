.tooltip_host {
    background: $color__white !important;
    border: none !important;
    padding: 0 !important;
}
.tooltip__arrow {
    background: $color__white !important;
    border: none !important;
}

.tooltip__container--content {
    @include hide-scrollbar();
}