$social-media-grandient-light: #2655c1;
$social-media-grandient-dark: #194098;

.social-media-link_icon {
    transition: opacity .2s linear;
    background: linear-gradient($social-media-grandient-light, $social-media-grandient-dark);
    border-radius: 50%;
    &:hover {
        color: $color__white;
        opacity: 1;
        &.facebook {
            background: linear-gradient(#19AFFF, #0062E0);
        }
        &.youtube {
            background: linear-gradient(#db2c27, #be181e);
        }
        &.instagram {
            background: linear-gradient(#833ab4, #fd1d1d, #fcb045);
        }
        &.twitter {
            background: #179cf0;
        }
        &.tiktok {
            background: #000;
        }
    }
}