$dark-blue: #163849;
$friends-dropdown-color-active: #f5a724;

.friends-dropdown__overlay {
    background-color: $popup--backdrop;
}

.friends-dropdown__tabs__container {
    @include scrollbar();
    @include touch-scroll();
    display: block !important;
}

.friends-dropdown__top-tabs__scroll {
    background-color: #00346f;
}

.friends-dropdown__top-tabs__scrollbar {
    background-color: $friends-dropdown-color-active;
}

.friends-dropdown__top-tabs__bclass {
    width: 13rem !important;
    color: $color__white;
    background-color: transparent;
    transition:  none !important;
    > p {
        color: $color__white;
        font-size: $text__size--normal;
        text-decoration: none;
    }
    &.friends-dropdown__top-tabs__current {
        background-color: transparent;
        > p {
            color: $friends-dropdown-color-active !important;
        }
    }
}