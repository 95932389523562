.notification-sign-host {
    font-size: 1.1rem;
    height: 1.75rem;
    min-width: 1.75rem;
    .notification-sign-host {
        height: 1.75rem !important;
        min-width: 1.75rem !important;
    }
        &.host-border {
            height: 1.75rem !important;
            min-width: 1.75rem !important;
        }
}

.notification-sign__wrapper {
    background: #ff084c;
    font-weight: $font-weight-bold;
}

.notification-sign__wrapper--border {
    border: none !important;
}